<template>
  <div
    class="form__field-box form__field-box--password"
  >
    <label
      v-if="label"
      class="form__label"
      :for="name"
    >
      {{ label }}
    </label>
    <div
      class="form__field-wrap"
    >
      <input
        :id="name"
        :type="type"
        class="form__field"
        :class="modelValue.length > 0 ? 'form__field--filed' : ''"
        :name="name"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="disabled ? true : false"
        @input="$emit('update:modelValue', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomPasswordInput',

    props: {

      modelValue: {
        type: [Number, String],
        default: ''
      },

      label: {
        type: String,
        default: ''
      },

      name: {
        type: String,
        default: ''
      },

      placeholder: {
        type: String,
        default: ''
      },

      disabled: {
        type: String,
        default: ''
      },

    },
    data() {
      return {
        type: "password"
      }
    },
    methods: {

    },
      
    computed: {

    }
  }
</script>
<template>
	<div class="robot-strategy-type__info">
		<div class="robot-strategy-type__name">
			<span>{{robotStrategyTitle}}</span>:
			<span
				:class="robotStrategy === 'Aggressive' ? 'agressive' : 'stable'"
			>{{robotStrategy}}</span>
		</div>
		<div class="robot-strategy-type__img">
			<img :src="strategyTypeImgUrl" :alt="strategyTypeImgAlt">
			<div class="robot-strategy-type__img-title">{{strategyTypeImgTitle}}</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "RobotStrategyType",
		props: {
      robotStrategyTitle: {
        type: String,
				default: ''
			},
      robotStrategy: {
        type: String,
				default: ''
			},
      strategyTypeImgUrl: {
        type: String,
				default: ''
			},
      strategyTypeImgAlt: {
        type: String,
				default: ''
			},
      strategyTypeImgTitle: {
        type: String,
				default: ''
			}
		}
  }
</script>
<template>
  <div class="form__field-box">
    <div class="form__field-container">
      <input
        :id="id"
        type="radio" 
        class="form__field-radio-group-item" 
        :value="value"
        :name="name"
        :checked="modelValue === value"
        @change="$emit('update:modelValue', $event.target.value)"
      >
      <label class="form__field-label form__field-label--radio-group-item"
             :for="id"
      >
        <slot></slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomRadioGroupItem",
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    value: {
      type: String || Number,
      default: ''
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
    modelValue: {
      type: String || Number,
      default: ''
    }
  }
}
</script>
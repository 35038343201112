<template>
	<header
		id="header"
		class="header">
		<div class="center">
			<Logo />
			<div class="header__tools">
				<div class="header__info">
					<HeaderInfoItem
						v-for="(item, index) in itemInfoData"
						:key="index"
						:itemInfo="item"
					/>
				</div>
				<Lang />
				<div
					:class="{'open' : isOpen}"
					class="btn-menu"
					@click="showMenu"
				>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
  import Logo from "@/components/Logo";
  import Lang from "@/components/Lang";
  import HeaderInfoItem from "@/components/header/HeaderInfoItem";

  export default {
    name: "Header",
    props: {

    },
		components: {
      Logo,
      Lang,
      HeaderInfoItem
		},
    data() {
      return {
        isOpen: false,
        itemInfoData: [
          {
            imgUrl: require('@/assets/img/svg/icn-mail.svg'),
            alt: 'mail',
            notice: 12
          },
          {
            imgUrl: require('@/assets/img/svg/icn-mess.svg'),
            alt: 'mess'
          },
          {
            imgUrl: require('@/assets/img/svg/icn-user.svg'),
            alt: 'user'
          }
        ]
      }
    },
		methods: {
      showMenu() {
        this.isOpen = !this.isOpen;
        this.$bus.$emit('isShowMobMenu');
			}
		},
		created() {
      this.$bus.$on('closeMobMenu', () => {
        this.isOpen = false;
      });
    }
  }
</script> 
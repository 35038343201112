<template>
	<div class="profile-account">
		<div class="profile-account__columns">
			<div class="profile-account__item profile-account__item--wrap">
				<div class="profile-account__info">
					<AccountAvatar
						:avatarImgUrl="avatarImgUrl"
						:avatarText="avatarText"
					/>

					<div class="profile-account__info-column">
						<div class="profile-account__info-id">{{profileAccountList.id}}</div>
						<div class="profile-account__info-email">{{profileAccountList.email}}</div>
						<div class="profile-account__info-block">
							<div class="profile-account__info-title">{{profileAccountList.infoTitle}}:</div>
							<div class="profile-account__info-date-block">
								<div class="profile-account__info-date">{{profileAccountList.date}}</div> /
								<div class="profile-account__info-ip">IP: {{profileAccountList.ip}}</div>
							</div>
						</div>
						<div class="btn btn--outline">{{profileAccountList.btnAddLog}}</div>
					</div>
				</div>
			</div>

			<div class="profile-account__item profile-account__item--verify">
				<div class="btn btn--green">{{profileAccountList.accVerificationText}}</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.personalInfoTitle"
					/>

					<div class="btn btn--outline">{{profileAccountList.btnAddPersonalInfo}}</div>
				</div>
			</div>

			<div class="profile-account__item profile-account__item--lang">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.langSystemTitle"
					/>

					<Lang />
				</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.changePassTitle"
					/>

					<div class="btn btn--outline">{{profileAccountList.btnChangePass}}</div>
				</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.apiKeyTitle"
					/>

					<div class="btn btn--outline">{{profileAccountList.btnApiKeyAdd}}</div>
				</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.deletePersonalInfoTitle"
					/>

					<div class="btn btn--danger">{{profileAccountList.btnDeletePersonalInfo}}</div>
				</div>
			</div>
		</div>

		<div class="profile-account__columns">
			<div class="profile-account__item">
				<AuthenticationBlock
					:btnAuthenticationTitle="profileAccountList.btnAuthenticationTitle"
					:authenticationTitle="profileAccountList.authenticationTitle"
					:authenticationText="profileAccountList.authenticationText"
					:authenticationSubText="profileAccountList.authenticationSubText"
				/>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.authenticationGoogle"
						:imgUrl="profileAccountList.authenticationGoogleImgUrl"
					/>

					<button
						:class="profileAccountList.isActiveBtnAuthenticationGoogle ? 'btn--on' : 'btn--off'"
						class="btn">{{profileAccountList.btnAuthenticationGoogle}}</button>
				</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<ProfileAccountTitle
						:title="profileAccountList.authenticationEmail"
						:imgUrl="profileAccountList.authenticationEmailImgUrl"
					/>

					<button
						:class="profileAccountList.isActiveBtnAuthenticationEmail ? 'btn--on' : 'btn--off'"
						class="btn">{{profileAccountList.btnAuthenticationEmail}}</button>
				</div>
			</div>

			<div class="profile-account__item">
				<div class="profile-account__row">
					<div class="profile-account__row-column">
						<ProfileAccountTitle
							:title="profileAccountList.yourIpFiltrTitle"
							:imgUrlToolTip="profileAccountList.imgUrlToolTip"
						/>

						<vSelect
							v-model="selectedIp"
							class="custom-select"
							:options="ipList"
						/>
					</div>
					<div class="profile-account__row-column">
						<ProfileAccountTitle
							:title="profileAccountList.yourIpTitle"
						/>

						<div class="btn btn--outline">{{profileAccountList.btnYourIp}}</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
  import Lang from "@/components/Lang";
  import vSelect from 'vue-select';
  import AccountAvatar from "@/components/profile-account-item/AccountAvatar";
  import ProfileAccountTitle from "@/components/profile-account-item/ProfileAccountTitle";
  import AuthenticationBlock from "@/components/profile-account-item/AuthenticationBlock";

  export default {
    name: "ProfileAccountItem",
		components: {
      Lang,
      vSelect,
      AccountAvatar,
      ProfileAccountTitle,
      AuthenticationBlock
		},
		props: {
      profileAccountList: {
        type: Object,
				default: {}
			},
		},
		data() {
      return {
        avatarImgUrl: require('@/assets/img/svg/def-icn-avatar.svg'),
        avatarText: "Добавить Фото",
        ipList: [
          '000.000.000.000', '000.000.000.001', '000.000.000.002'
        ],
        selectedIp: '000.000.000.000',
        isActiveBtnAuthentication: false
			}
		}
  }
</script>
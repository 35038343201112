<template>
	<div class="paginations__wrap">
		<ul>
			<li
				v-for="(item,i) in paginationsList"
				:key="i"
			>
				<a
					:class="{'active' : item.active}"
					v-if="item.num"
					:href="item.link">{{item.num}}</a>
				<a
					v-else-if="item.next"
					:href="item.link">{{item.next}}</a>
				<a
					v-else="item.last"
					:href="item.link">{{item.last}}</a>
			</li>
		</ul>
	</div>
</template>

<script>
  export default {
    name: "Paginations",
		props: {
      paginationsList: {
        type: Array,
				default: []
			}
		}
  }
</script>
<template>
	<div class="state-account">
		<div class="state-account__title">{{stateOfAccountTitle}}</div>
		<div class="state-account__table">
			<div
				v-for="(item,i) in StateOfAccountList"
				class="state-account__table-item">
				<div class="state-account__table-title">{{item?.title}}</div>
				<div class="state-account__table-content">{{item?.content}}</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "StateOfAccount",
    props: {
      stateOfAccountTitle: {
        type: String,
        default: ''
      },
      StateOfAccountList: {
        type: Array,
        default: []
      }
    }
  }
</script>
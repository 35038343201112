<template>
  <span
    class="marker"
    :class="markerClassName"
  >
    {{markerText}}
  </span>
</template>

<script>
  export default {
    name: "Marker",

    props: {
      markerText: { 
        type: String,
        default: ''
      },
      markerClassName: {
        type: String,
        default: ''
      }, 
    }
  }
</script> 
<template>
	<div class="robot__tariff-plans">
		<div class="robot__tariff-plans-wrap">
			<div class="robot__tariff-plans-title">{{tarifPlansTitle}}</div>
			<div class="robot__tariff-plans-list">
				<div
					v-for="elem in tarifPlansList"
					:key="elem.id"
					@click="openModalTarif(elem)"
					class="robot__tariff-plans-item">
<!--					<CustomRadioGroupItem-->
<!--						:name="name"-->
<!--						:value="elem.num"-->
<!--						:id="elem.date"-->
<!--						v-model="selectedModel"-->
<!--					>-->
<!--						<span>{{elem.date}} {{elem.month}}</span>-->

<!--						<span class="robot__tariff-plans-item&#45;&#45;num">$ {{elem.num}}.00</span>-->
<!--					</CustomRadioGroupItem>-->

					<div class="robot__tariff-plans-item--date">{{elem.date}} {{elem.month}}</div>

					<div class="robot__tariff-plans-item--num">$ {{elem.num}}.00</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import CustomRadioGroupItem from "@/components/common/RadioGroup/CustomRadioGroupItem";

  export default {
    name: "RobotTariffPlans",
		components: {
      CustomRadioGroupItem
		},
		props: {
      tarifPlansTitle: {
        type: String,
        default: ''
      },
      tarifPlansList: {
        type: Array,
        default: []
      },
		},
		methods: {
      openModalTarif(elem) {
        this.$bus.$emit('open-modal-tarif-plans', elem);
			}
		}
  }
</script>
<template>
  <div
    class="modal"
    :class="(isOpen ? 'show' : '') + ' ' + addClass"
    @click="closeModal">
    <div
      class="modal__dialog"
      @click.stop
    >

			<div class="modal__header">
				<Logo />
				<button class="btn__close "
								@click="closeModal()" ></button>
			</div>
      <div class="modal__content">

        <div v-if="modalTitle"
             class="modal__title">{{modalTitle}}</div>
        <div
          v-if="modalMsg"
          class="modal__text">{{modalMsg}}</div>
        <slot
					:elem="elem"
				></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import Logo from "@/components/Logo";

  export default {
    name: 'DefaultModal',
		components: {
      Logo
		},
    props: {
      modalTitle: {
        type: String,
        required: true
      },
      modalMsg: {
        type: String,
        required: true
      },
      modalName: {
        type: String,
        required: true
      },
      addClass: {
        type: String,
        default: ''
      },

      forceOpen: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        isOpen: this.forceOpen,
        date: '',
        month: '',
        num: '',
				elem: {}
      }
    },

    methods: {
      closeModal() {
        this.isOpen = false;
      }
    },
    created() {
      this.$bus.$on('open-modal-' + this.modalName, (elem) => {
				if(elem) {
          this.elem = elem;
				}

        this.isOpen = true;
      });
      this.$bus.$on('close-modal-' + this.modalName, (elem) => {
        this.closeModal();
      });
    }
  }
</script>
<template>
	<div class="social">
		<div class="social__title">
			{{socialTitle}}
		</div>
		<div class="social__list">
			<a
				v-for="item in socialInfoData"
				:key="item?.imgUrl"

				class="social__list-item"
				:href="item?.link">
				<img
					:src="item?.imgUrl"
					:alt="item?.alt"
				/>
			</a>
		</div>
	</div>

</template>

<script>
  export default {
    name: "Social",
    data() {
      return {
        socialTitle: 'ПОДПИСЫВАЙТЕСЬ',
        socialInfoData: [
          {
            link: '##',
            imgUrl: require('@/assets/img/svg/tweeter.svg'),
            alt: 'tweeter'
          },
          {
            link: '##',
            imgUrl: require('@/assets/img/svg/telegramm.svg'),
            alt: 'telegramm'
          },
          {
            link: '##',
            imgUrl: require('@/assets/img/svg/youtube.svg'),
            alt: 'youtube'
          }
        ]
      }
    }
  }
</script>
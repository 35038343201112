<template>
	<div class="profile-account__authentication">
		<div class="profile-account__authentication-block">
			<div
				:class="!isActive ? 'btn--danger' : 'btn--success'"
				class="btn ">{{btnAuthenticationTitle}}</div>
			<div class="profile-account__authentication-title">{{authenticationTitle}}</div>
			<div
				v-html="authenticationText"
				class="profile-account__authentication-text"></div>
		</div>
		<div
			v-html="authenticationSubText"
			class="profile-account__authentication-subtext"></div>
	</div>
</template>

<script>
  export default {
    name: "AuthenticationBlock",
		props: {
      isActive: {
        type: Boolean,
				default: false
			},
      btnAuthenticationTitle: {
        type: String,
        default: ''
      },
      authenticationTitle: {
        type: String,
        default: ''
      },
      authenticationText: {
        type: String,
        default: ''
      },
      authenticationSubText: {
        type: String,
        default: ''
      }
		}
  }
</script>
<template>
	<div
		class="sort-page__wrap">
		<span>{{sortPageTitle }}</span>
		<ul>
			<li
				:class="{'active' : item.active}"
				v-for="(item, index) in sortPageList"
				:key="index"
			>{{item.num}}</li>
		</ul>
		<span>{{sortPageText}}</span>
	</div>
</template>

<script>
  export default {
    name: "SortPage",
		props: {
      sortPageTitle: {
        type: String,
				default: ''
			},
      sortPageText: {
        type: String,
				default: ''
			},
      sortPageList: {
        type: Array,
        default: []
      }
		}
  }
</script>
<template>
	<div class="default-table__wrap default-table__wrap-contactus">
		<div class="default-table">
			<div
				class="default-table__header">
				<div
					v-for="(el,i) in headerList"
					:key="i"
					class="default-table__header-item">
					<div v-if="el.title" class="default-table__header-item-title">
						{{el?.title}}
					</div>
					<div v-else class="default-table__header-item-block">
						<CustomCheckBox
							name="el.checkboxSelectAll"
						>
							{{el?.checkboxLabelSelect}}
						</CustomCheckBox>
					</div>
				</div>
			</div>
			<div class="default-table__body">
				<div
					v-for="(row,i) in rowsList"
					:key="i"
					class="default-table__row">
					<div
						v-for="(column, index) in row?.columnsList"
						:key="index"
						class="default-table__item"
					>
						<div v-if="column.title" class="default-table__mob-title">{{column?.title}}</div>

						<span v-if="column.text">{{column?.text}}</span>
						<div v-else class="default-table__header-item-block">
							<CustomCheckBox
								:name="column.checkBoxId"
							>
								{{el?.checkboxLabelSelect}}
							</CustomCheckBox>
						</div>
					</div>

					<div v-if="row.text" class="default-table__row-bottom">

						<div class="support-answer">
							<div class="support-answer__header">
								<Logo />
							</div>
							<div class="support-answer__body">
								<h3> {{answerList.name}}</h3>
								<div class="text" v-html="row?.text"></div>
								<div class="support-answer__body-bottom">
									<p>{{answerList.text}}</p>
									<p><strong>{{answerList.subtext}}</strong></p>
								</div>
							</div>
							<div class="support-answer__footer">
								<div class="logo">
									<router-link :to="{name: 'Home'}">
										<picture>
											<source :srcset="require('@/assets/img/svg/finflow_lab_logo_darkblue.svg')"
															media="(max-width: 1300px)">
											<img :src="require('@/assets/img/svg/finflow_lab_logo_darkblue.svg')"
													 :alt="logo">
										</picture>
									</router-link>
								</div>
								<Social />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import CustomCheckBox from "@/components/common/CustomCheckBox";
  import Logo from "@/components/Logo";
  import Social from "@/components/Social";

  export default {
    name: "ContactUsTable",
    components: {
      CustomCheckBox,
      Logo,
      Social
    },
    props: {
      answerList: {
        type: Object,
				default: {}
			},
      headerList: {
        type: Array,
        default: []
      },
      rowsList: {
        type: Array,
        default: []
      },
      columnsList: {
        type: Array,
        default: []
      }
    },
  }
</script>
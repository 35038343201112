<template>
	<div class="robot__tariff-btns-wrap">
		<CustomButton
			class="btn--outline"
			v-for="btnel in btnTitle"
		>
			{{btnel?.title}}
		</CustomButton>
	</div>
</template>

<script>
  import CustomButton from "@/components/common/CustomButton";

  export default {
    name: "RobotTariffBtns",
		components: {
      CustomButton
		},
		props: {
      btnTitle: {
        type: Array,
				default: []
			}
		}
  }
</script>
<template>
	<div class="breadcrumbs">
		<ul>
			<li
				v-for="(item, index) in breadcrumbsList"
				:key="index"
			>
				<a
					v-if="item.link"
					:href="item.link"
				>
					{{item.title}}
				</a>

				<span
					v-else
				>
					{{item.title}}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
  export default {
    name: "Breadcrumbs",
		props: {
      breadcrumbsList: {
        type: Array,
				default: []
			}
		}
  }
</script>
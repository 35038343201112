<template>
	<div class="login__wrap">
		<div class="login__wrap-header">
			<Logo />
		</div>
		<div class="login__wrap-content">
			<LoginForm />
		</div>
	</div>
</template>

<script>
  import LoginForm from "@/components/forms/LoginForm";
  import Logo from "@/components/Logo";

  export default {
    name: "Login",
		components: {
      LoginForm,
      Logo
		}
  }
</script>
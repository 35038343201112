<template>
	<div
		class="robot-card__wrap robot-card__trade">
		<div
			:class="{'demo': robotCardListInfo.demoTrade}"
			class="robot-card__header">
			<div class="robot-card__name">{{robotCardListInfo?.robotCardName}}</div>
			<div class="robot-card__right-block">
				<div v-if="robotCardListInfo.demoTrade" class="robot-card__trade-demo">{{robotCardListInfo?.demoTrade}}</div>
				<div class="robot-card__trade-number">{{robotCardListInfo?.numberTrade}}</div>
				<div class="robot-card__trade-name">{{robotCardListInfo?.numberTradeName}}</div>
			</div>
		</div>
		<div class="robot-card__block-wrapper">
			<div class="robot-card__block">

				<RobotStrategyType
					:robotStrategyTitle="robotCardListInfo?.robotStrategyTitle"
					:robotStrategy="robotCardListInfo?.robotStrategy"
					:strategyTypeImgUrl="robotCardListInfo?.strategyTypeImgUrl"
					:strategyTypeImgAlt="robotCardListInfo?.strategyTypeImgAlt"
					:strategyTypeImgTitle="robotCardListInfo?.strategyTypeImgTitle"
				/>

				<div class="robot-strategy-profit__info">
					<div class="robot-strategy-profit">
						<RobotStrategyProfitInfo
							:strategyProfitTitle="robotCardListInfo?.strategyProfitTitle"
							:strategyProfitNum="robotCardListInfo?.strategyProfitNum"
							:strategyProfitUp="robotCardListInfo?.strategyProfitUp"
							:strategyMaximumTitle="robotCardListInfo?.strategyMaximumTitle"
							:strategyMaximumNum="robotCardListInfo?.strategyMaximumNum"
							:currentBalanceTitle="robotCardListInfo?.currentBalanceTitle"
							:currentBalance="robotCardListInfo?.currentBalance"
						/>

					</div>

					<div class="robot-strategy-profit">

						<RobotStrategyProfitInfo
							:startDateTitle="robotCardListInfo?.startDateTitle"
							:startDate="robotCardListInfo?.startDate"
							:endDateTitle="robotCardListInfo?.endDateTitle"
							:endDate="robotCardListInfo?.endDate"
							:equityTitle="robotCardListInfo?.equityTitle"
							:equity="robotCardListInfo?.equity"
						/>
					</div>
				</div>

				<CustomButton
					class="btn--outline"
					@click="robotsDetails"
				>
					{{robotCardListInfo?.btnInfoTitle}}
				</CustomButton>
			</div>

<!--			<div class="robot-strategy-profit__text">-->
<!--				{{robotCardListInfo?.strategyTypeText}}-->
<!--			</div>-->
			<RobotCardImg
				:imgUrl="robotCardListInfo?.imgUrl"
				:imgAlt="robotCardListInfo?.imgAlt"
			/>

		</div>
	</div>
</template>

<script>
  import CustomRadioGroupItem from "@/components/common/RadioGroup/CustomRadioGroupItem";
  import CustomButton from "@/components/common/CustomButton";
  import RobotStrategyType from "@/components/robot-card/RobotStrategyType";
  import RobotCardImg from "@/components/robot-card/RobotCardImg";
  import RobotStrategyProfitInfo from "@/components/robot-card/RobotStrategyProfitInfo";

  export default {
    name: "RobotCardTrade",
    components: {
      CustomRadioGroupItem,
      CustomButton,
      RobotStrategyType,
      RobotCardImg,
      RobotStrategyProfitInfo
    },
    props: {
      robotCardListInfo: {
        type: Object,
        default: {}
      },
    },
		methods: {
      robotsDetails() {
        this.$router.push('/my-robots-details');
			}
		}
  }
</script>
<template>
	<div
		v-if="strategyProfitTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{strategyProfitTitle}} </div>
		<div class="robot-strategy-profit__wrap">
			<div class="robot-strategy-item__content">{{strategyProfitNum}}</div>
			<span
				v-if="strategyProfitUp"
				class="robot-strategy-profit__up">{{strategyProfitUp}}</span>
			<span
				v-else
				class="robot-strategy-profit__down">{{strategyProfitDown}}</span>
		</div>
	</div>

	<div
		v-if="strategyMaximumTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{strategyMaximumTitle}}</div>
		<div class="robot-strategy-item__content">
			{{strategyMaximumNum}}
		</div>
	</div>

	<div
		v-if="currentBalanceTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{currentBalanceTitle}}</div>
		<div class="robot-strategy-item__content">{{currentBalance}}</div>
	</div>

	<div
		v-if="startDateTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{startDateTitle}}</div>
		<div class="robot-strategy-item__content">{{startDate}}</div>
	</div>

	<div
		v-if="endDateTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{endDateTitle}}</div>
		<div class="robot-strategy-item__content">{{endDate}}</div>
	</div>

	<div
		v-if="equityTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{equityTitle}}</div>
		<div class="robot-strategy-item__content">{{equity}}</div>
	</div>

	<div
		v-if="brokerTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{brokerTitle}}</div>
		<div class="robot-strategy-item__content">{{brokerName}}</div>
	</div>

	<div
		v-if="countTitle"
		class="robot-strategy-item">
		<div class="robot-strategy-item__title">{{countTitle}}</div>
		<div class="robot-strategy-item__content">{{countInfo}}</div>
	</div>
</template>

<script>
  export default {
    name: "RobotStrategyProfitInfo",
		props: {
      strategyProfitTitle: {
        type: String,
				default: ''
			},
      countTitle: {
        type: String,
				default: ''
			},
      countInfo: {
        type: String,
				default: ''
			},
			brokerTitle: {
        type: String,
				default: ''
			},
      brokerName: {
        type: String,
				default: ''
			},
			equityTitle: {
        type: String,
				default: ''
			},
      equity: {
        type: String,
				default: ''
			},
			endDateTitle: {
        type: String,
				default: ''
			},
      endDate: {
        type: String,
				default: ''
			},
			startDateTitle: {
        type: String,
				default: ''
			},
      startDate: {
        type: String,
				default: ''
			},
			currentBalanceTitle: {
        type: String,
				default: ''
			},
      currentBalance: {
        type: String,
				default: ''
			},
      strategyProfitNum: {
        type: String,
				default: ''
			},
      strategyProfitUp: {
        type: String,
				default: ''
			},
      strategyProfitDown: {
        type: String,
				default: ''
			},
      strategyMaximumTitle: {
        type: String,
				default: ''
			},
      strategyMaximumNum: {
        type: String,
				default: ''
			},
		}
  }
</script>
<template>
	<div class="logo">
		<router-link :to="{name: 'Home'}">
			<picture>
				<source :srcset="require('@/assets/img/svg/finflow_lab_logo.svg')"
								media="(max-width: 1300px)">
				<img :src="require('@/assets/img/svg/finflow_lab_logo.svg')"
						 :alt="logo">
			</picture>
		</router-link>
	</div>
</template>

<script>
  export default {
    name: "Logo"
  }
</script>
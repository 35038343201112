<template>
	<RobotStrategyType
		:robotStrategyTitle="robotCardListInfo?.robotStrategyTitle"
		:robotStrategy="robotCardListInfo?.robotStrategy"
		:strategyTypeImgUrl="robotCardListInfo?.strategyTypeImgUrl"
		:strategyTypeImgAlt="robotCardListInfo?.strategyTypeImgAlt"
		:strategyTypeImgTitle="robotCardListInfo?.strategyTypeImgTitle"
	/>

	<div class="robot-strategy-profit__info">
		<div class="robot-strategy-profit">
			<RobotStrategyProfitInfo
				:strategyProfitTitle="robotCardListInfo?.strategyProfitTitle"
				:strategyProfitNum="robotCardListInfo?.strategyProfitNum"
				:strategyProfitUp="robotCardListInfo?.strategyProfitUp"
				:strategyMaximumTitle="robotCardListInfo?.strategyMaximumTitle"
				:strategyMaximumNum="robotCardListInfo?.strategyMaximumNum"
			/>
		</div>
	</div>

</template>

<script>

  import RobotStrategyType from "@/components/robot-card/RobotStrategyType";
  import RobotStrategyProfitInfo from "@/components/robot-card/RobotStrategyProfitInfo";

  export default {
    name: "RobotConnecting",
    components: {
      RobotStrategyType,
      RobotStrategyProfitInfo
    },
    props: {
      robotCardListInfo: {
        type: Object,
        default: {}
      },
    },
  }
</script>
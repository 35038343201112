<template>
	<div class="main-content main-content--myrobots-detatils">
		<div class="title">{{pageRobotsTitle}}</div>
		<div class="robot__details-columns">
			<div class="robot__details-column myrobots-detatils__column">
				<div class="robot__details-column-block">
					<RobotStrategyType
						:robotStrategyTitle="robotStrategyTitle"
						:robotStrategy="robotStrategy"
						:strategyTypeImgUrl="strategyTypeImgUrl"
						:strategyTypeImgAlt="strategyTypeImgAlt"
						:strategyTypeImgTitle="strategyTypeImgTitle"
					/>
					<div class="robot-strategy-profit__info">
						<div class="robot-strategy-profit">
							<RobotStrategyProfitInfo
								:startDateTitle="robotCardListInfo?.brokerTitle"
								:startDate="robotCardListInfo?.brokerName"
								:endDateTitle="robotCardListInfo?.countTitle"
								:endDate="robotCardListInfo?.countInfo"
							/>
						</div>
						<div class="robot-strategy-profit">
							<RobotStrategyProfitInfo
								:startDateTitle="robotCardListInfo?.startDateTitle"
								:startDate="robotCardListInfo?.startDate"
								:endDateTitle="robotCardListInfo?.endDateTitle"
								:endDate="robotCardListInfo?.endDate"
							/>
						</div>
						<div class="robot-strategy-profit robot-strategy-start">
							<div class="robot-strategy-item">
								<div class="robot-strategy-item__title">
									{{statusTitle}}
								</div>
								<div class="robot-strategy-item__content ">
									<div class="robot-strategy-item__img">
										<img :src="statusImg" :alt="statusAlt">
									</div>
									<span>{{statusStart}}</span>
								</div>
							</div>
							<CustomButton class="btn--danger">Стоп</CustomButton>
						</div>
					</div>
				</div>
				<RobotCardImg
					:imgUrl="robotCardListInfo?.imgUrl"
					:imgAlt="robotCardListInfo?.imgAlt"
				/>
			</div>
			<div class="robot__details-column">
				<StateOfAccount
					:stateOfAccountTitle="stateOfAccountTitle"
					:StateOfAccountList="StateOfAccountList"
				/>
			</div>
		</div>

		<div class="myrobots-detatils__wrap myrobots-detatils__wrap-tabs">
			<div class="myrobots-detatils__top">
				<div class="myrobots-detatils__title">{{myRobotsDetailsTitle}}</div>

				<SortPage
					:sortPageTitle="sortPageTitle"
					:sortPageText="sortPageText"
					:sortPageList="sortPageList"
				/>
			</div>

			<Tabs
				:itemsTabsHeader="itemsTabsHeader"
			>
				<template #tab-content-1>
					<TransactionsTable
						:headerList="headerList"
						:rowsList="rowsList"
					/>
				</template>

				<template #tab-content-2>
					2
				</template>
			</Tabs>

			<Paginations
				:paginationsList="paginationsList"
			/>
		</div>

		<div class="myrobots-detatils__wrap">
			<div class="myrobots-detatils__top">
				<div class="myrobots-detatils__title">{{myRobotsHistoryDetailsTitle}}</div>

				<SortPage
					:sortPageTitle="sortPageTitle"
					:sortPageText="sortPageText"
					:sortPageList="sortPageList"
				/>
			</div>

			<TransactionsTable
				:headerList="headerHistiryList"
				:rowsList="rowsHistoryList"
			/>

			<Paginations
				:paginationsList="paginationsList"
			/>
		</div>
	</div>
</template>

<script>
  import RobotStrategyType from "@/components/robot-card/RobotStrategyType";
  import RobotStrategyProfitInfo from "@/components/robot-card/RobotStrategyProfitInfo";
  import CustomButton from "@/components/common/CustomButton";
  import RobotCardImg from "@/components/robot-card/RobotCardImg";
  import StateOfAccount from "@/components/StateOfAccount";
  import Tabs from "@/components/tabs/Tabs";
  import SortPage from "@/components/SortPage";
  import Paginations from "@/components/Paginations";
  import TransactionsTable from "@/components/wallet/TransactionsTable";

  export default {
    name: "MyRobotsDetails",
		components: {
      RobotStrategyType,
      RobotStrategyProfitInfo,
      CustomButton,
      RobotCardImg,
      StateOfAccount,
      Tabs,
      SortPage,
      Paginations,
      TransactionsTable
		},
		data() {
      return {
        myRobotsHistoryDetailsTitle: "История сделок",
        headerHistiryList: [
          {
            title: "Время открытия"
          },
          {
            title: "Тип"
          },
          {
            title: "Объем"
          },
          {
            title: "Символ"
          },
          {
            title: "Цена"
          },
          {
            title: "S/L"
          },
          {
            title: "T/P"
          },
          {
            title: "Время закрытия"
          },
					{
            title: "Цена"
          },
          {
            title: "Комиссия"
          },
          {
            title: "Своп"
          },
          {
            title: "Прибыль"
          },
          {
            title: "Комментарий"
          }
        ],
        rowsHistoryList: [
          {
            columnsList: [
              {

                title: "Время открытия",
                text: "2023.05.08 11:45",
              },
              {

                title: "Тип",
                text: "Buy",
              },
              {

                title: "Объем",
                text: "0.03",
              },
              {
                title: "Символ",
                text: "EURNZD"
              },
              {
                title: "Цена",
                text: "1.74549",
              },
              {

                title: "S/L",
                text: " ",
              },
              {

                title: "T/P",
                text: " ",
              },
              {

                title: "Время закрытия",
                text: "2023.05.08 11:45",
              },
              {

                title: "Цена",
                text: "1.75223",
              },
              {
                title: "Комиссия",
                text: "1.75223"
              },
              {

                title: "Своп",
                text: "-0.03",
              },
              {

                title: "Прибыль",
                text: "-135.33",
              },
              {
                title: "Комментарий",
                text: ''
              }
            ]
          },
          {
            columnsList: [
              {

                title: "Время открытия",
                text: "2023.05.08 11:45",
              },
              {

                title: "Тип",
                text: "Buy",
              },
              {

                title: "Объем",
                text: "0.03",
              },
              {
                title: "Символ",
                text: "EURNZD"
              },
              {
                title: "Цена",
                text: "1.74549",
              },
              {

                title: "S/L",
                text: " ",
              },
              {

                title: "T/P",
                text: " ",
              },
              {

                title: "Время закрытия",
                text: "2023.05.08 11:45",
              },
              {

                title: "Цена",
                text: "1.75223",
              },
              {
                title: "Комиссия",
                text: "1.75223"
              },
              {

                title: "Своп",
                text: "-0.03",
              },
              {

                title: "Прибыль",
                text: "-135.33",
              },
              {
                title: "Комментарий",
                text: ''
              }
            ]
          },
        ],

        rowsList: [
          {
            columnsList: [
              {
                title: "Тикет",
                text: "189829",
              },
              {

                title: "Время",
                text: "2023.05.08 11:45",
              },
              {

                title: "Тип",
                text: "Buy",
              },
              {

                title: "Объем",
                text: "0.03",
              },
              {
                title: "Символ",
                text: "EURNZD"
              },
              {
                title: "Цена",
                text: "1.74549",
              },
              {

                title: "S/L",
                text: " ",
              },
              {

                title: "T/P",
                text: " ",
              },
              {

                title: "Цена",
                text: "1.75223",
              },
              {
                title: "Комиссия",
                text: "1.75223"
              },
              {

                title: "Своп",
                text: "-0.03",
              },
              {

                title: "Прибыль",
                text: "-135.33",
              },
              {
                title: "Действие",
                imgUrl: require('@/assets/img/svg/icondelete.svg')
              }
            ]
          },
          {
            columnsList: [
              {
                title: "Тикет",
                text: "189829",
              },
							{

                title: "Время",
                text: "2023.05.08 11:45",
							},
							{

                title: "Тип",
                text: "Buy",
							},
							{

                title: "Объем",
                text: "0.03",
							},
							{
                title: "Символ",
                text: "EURNZD"
							},
              {
                title: "Цена",
                text: "1.74549",
              },
              {

                title: "S/L",
                text: " ",
              },
              {

                title: "T/P",
                text: " ",
              },
              {

                title: "Цена",
                text: "1.75223",
              },
              {
                title: "Комиссия",
                text: "1.75223"
              },
              {

                title: "Своп",
                text: "-0.03",
              },
              {

                title: "Прибыль",
                text: "-135.33",
              },
              {
                title: "Действие",
                imgUrl: require('@/assets/img/svg/icondelete.svg')
              }
            ]
          }
				],
        headerList: [
          {
            title: "Тикет"
          },
          {
            title: "Время"
          },
          {
            title: "Тип"
          },
          {
            title: "Объем"
          },
          {
            title: "Символ"
          },
          {
            title: "Цена"
          },
          {
            title: "S/L"
          },
          {
            title: "T/P"
          },
          {
            title: "Цена"
          },
          {
            title: "Комиссия"
          },
          {
            title: "Своп"
          },
          {
            title: "Прибыль"
          },
          {
            title: "Действие"
          }
        ],

        paginationsList: [
          {
            num: '1',
            active: true,
            link: '##'
          },
          {
            num: '2',
            link: '##'
          },
          {
            num: '3',
            link: '##'
          },
          {
            next: '>',
            link: '##'
          },
          {
            last: '>>',
            link: '##'
          }
        ],
        sortPageList: [
          {
            num: '15',
            active: true
          },
          {
            num: '50',
          },
          {
            num: '100',
          },
          {
            num: '200',
          },
        ],
        sortPageTitle: "Показать",
        sortPageText: "строк",
        myRobotsDetailsTitle: "Открытые позиции",
        itemsTabsHeader: ['Открытые позиции', 'Отложенные ордера'],
        statusTitle: "Статус",
        statusImg: require('@/assets/img/svg/status-start.svg'),
        statusAlt: "start",
        statusStart: "Запущен",
        pageRobotsTitle: "Мои роботы | Steve USDEUR",
        robotStrategyTitle: "Тип стратегии",
        robotStrategy: "Aggressive",
        strategyTypeImgTitle: "MT4/MT5",
        strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
        strategyTypeImgAlt: "Aggressive",
        robotCardListInfo: {
          startDateTitle: "Дата подключения:",
          startDate: "09/28/2024",
          endDateTitle: "Дата окончания тарифа",
          endDate: "∞",
          brokerTitle: "Брокер",
          brokerName: "FlowBank",
          countTitle: "Номер счета",
          countInfo: "4378968934764",
          imgUrl: require('@/assets/img/personal_robot_01.png'),
          imgAlt: "Steve",
				},
        stateOfAccountTitle: 'Состояние счета',
        StateOfAccountList: [
					{
            title: "Баланс",
            content: "$ 280,854.27",
					},
					{
            title: "Эквити",
            content: "$ 98,200.39",
					},
          {
            title: "Текущая доходность",
            content: "1,227.6%",
          },
          {
            title: "Маржа",
            content: "$ 123.76",
          },
          {
            title: "Свободная маржа",
            content: "$ 11,339.69",
          },
          {
            title: "Уровень маржи",
            content: "79,111.19%",
          },
				]
			}
		}
  }
</script>
<template>
  <ul class="menu__list">
      <li
				v-for="(item, index) in menuListData"
				:key="index"
        class="menu__list-item"
				:class="item.active ? 'active' : ''"
      >
				<router-link
          :to="item.href"
          class="menu__list-link"
        >
					<span class="menu__list-icn">
						<img :src="item.icn" :alt="item.title">
					</span>
          <span class="menu__list-title">{{ item.title }}</span>
				</router-link>
      </li>
  </ul>
</template>

<script>
  export default {
    name: "MenuList",

    data() {
      return {

      }
    },

    props: {
      menuListData: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
		created() {
      this.$bus.$on('isShowMobMenu', () => {
        this.isOpen = !this.isOpen;
			});
    },
  }
</script>
<template>
	<div class="main-content main-content--referral">
		<div class="title">{{pageReferralTitle}}</div>
		<div class="referral__block--top">
			<div class="referral__block">
				<OverAllStatistics
					:overAllStatisticsTitle="overAllStatisticsTitle"
					:overAllStatisticsList="overAllStatisticsList"
				/>
			</div>
			<div class="referral__block-descr">
				{{referralDescrText}}
			</div>
		</div>

		<Tabs :itemsTabsHeader="itemsTabsHeader">
			<template #tab-content-1>
				<div class="referral__link-block">
					<div class="referral__link">
						<div class="referral__link-title">{{referralLinkTitle}}</div>
						<div class="referral__link-text">{{referralLinkText}}</div>
					</div>
					<div class="referral__link">
						<div class="referral__link-title">{{referralCodeTitle}}</div>
						<div class="referral__link-text">{{referralCodeText}}</div>
					</div>
				</div>
			</template>

			<template #tab-content-2>
				<div class="referral__banner-block">
					<div class="referral__banner-row">
						<a href="##" class="referral__banner referral__banner-250">
							<img :src="require('@/assets/img/Banner03.jpg')" alt="">
						</a>
						<a href="##" class="referral__banner referral__banner-300">
							<img :src="require('@/assets/img/Banner4.jpg')" alt="">
						</a>
						<div class="referral__banner-text">{{referralCopyText}}</div>
					</div>
					<a href="##" class="referral__banner referral__banner-720">
						<img :src="require('@/assets/img/Banner02.jpg')" alt="">
					</a>
					<a href="##" class="referral__banner referral__banner-460">
						<img :src="require('@/assets/img/Banner01.jpg')" alt="">
					</a>
				</div>
			</template>
		</Tabs>

		<div class="myrobots-detatils__wrap">
			<div class="myrobots-detatils__top">
				<div class="myrobots-detatils__title">{{referralsHistoryDetailsTitle}}</div>

				<SortPage
					:sortPageTitle="sortPageTitle"
					:sortPageText="sortPageText"
					:sortPageList="sortPageList"
				/>
			</div>

			<TransactionsTable
				:headerList="headerList"
				:rowsList="rowsList"
			/>

			<Paginations
				:paginationsList="paginationsList"
			/>
		</div>
	</div>
</template>

<script>
  import OverAllStatistics from "@/components/OverAllStatistics";
  import Tabs from "@/components/tabs/Tabs";
  import TransactionsTable from "@/components/wallet/TransactionsTable";
  import SortPage from "@/components/SortPage";
  import Paginations from "@/components/Paginations";

  export default {
    name: "ReferralProgram",
		components: {
      OverAllStatistics,
      Tabs,
      TransactionsTable,
      SortPage,
      Paginations
		},
		data() {
      return {
        referralsHistoryDetailsTitle: "История зачислений партнерского вознаграждения",
        sortPageTitle: "Показать",
        sortPageText: "строк",
        paginationsList: [
          {
            num: '1',
            active: true,
            link: '##'
          },
          {
            num: '2',
            link: '##'
          },
          {
            num: '3',
            link: '##'
          },
          {
            next: '>',
            link: '##'
          },
          {
            last: '>>',
            link: '##'
          }
        ],
        sortPageList: [
          {
            num: '15',
            active: true
          },
          {
            num: '50',
          },
          {
            num: '100',
          },
          {
            num: '200',
          },
        ],
        rowsList: [
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {

                title: "ID реферала",
                text: "156455466",
              },
              {

                title: "Сумма",
                text: "USD 20.00",
              },
              {

                title: "Комментарий",
                text: "Perfect Money",
              },
            ]
          },
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {

                title: "ID реферала",
                text: "156455466",
              },
              {

                title: "Сумма",
                text: "USD 20.00",
              },
              {

                title: "Комментарий",
                text: "Perfect Money",
              },
            ]
          }
        ],
        headerList: [
          {
            title: "Дата"
          },
          {
            title: "ID реферала"
          },
          {
            title: "Сумма"
          },
          {
            title: "Комментарий"
          },
        ],
        referralCopyText: "Кликните по выбранному баннеру чтобы скопировать HTML-код для вставки на сайт.",
        referralCodeTitle: "Ваш реферальный код",
        referralCodeText: "4936061",
				referralLinkTitle: "Ваша реферальная ссылка",
        referralLinkText: "https://finfly.io/partner/4936061",
        itemsTabsHeader: ['Реферальная ссылка', 'Баннеры'],
        pageReferralTitle: "Реферальная программа",
        referralDescrText: "*Партнерское вознаграждение зачисляется клиенту на его кошелек в системе. Распоряжаться партнерским вознаграждением он сможет на странице “Мой кошелек”, на странице Реферальная программа отображается только статистика. Отдельного реферального счета в системе не предусмотрено.",
        overAllStatisticsTitle: "Общая статистика",
        overAllStatisticsList: [
          {
            title: "Всего получено вознаграждения*",
            content: "$ 987,00",
          },
          {
            title: "Всего рефералов",
            content: "78 человек",
          },
				]
			}
		}
  }
</script>
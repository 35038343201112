<template>
	<div class="robot__tariff-plans-list">
		<div
			v-for="(item, index) in fixedPaymentList"
			:key="index"
			class="robot__tariff-plans-item"
			@click="changeAmount(item.amount)"
		>
			<div class="robot__tariff-plans-item--num">${{item.title}}</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "ChangeAmount",
    props: {
      fixedPaymentList: {
        type: Array,
        required: true
      }
    },
    methods: {
      changeAmount(val) {
        console.log(val, 'vvvvvvvvv')
        this.$emit('change-amount', val)
      }
    }
  }
</script>
<template>
	<div
		v-for="item in walletCardBlockList"
		:key="item?.title"
		class="wallet-card__block-item">
		<div class="wallet-card__block-title">{{item?.title}}</div>
		<div class="wallet-card__block-content">{{item?.content}}</div>
	</div>
</template>

<script>
  export default {
    name: "WalletCardBlock",
    props: {
      walletCardBlockList: {
        type: Object,
        default: {}
      }
    }
  }
</script>
<template>
	<footer class="footer">
		<div class="footer--top">
			<div class="center">
				<div class="logo">
					<router-link :to="{name: 'Home'}">
						<picture>
							<source :srcset="require('@/assets/img/svg/finflow_lab_logo_darkblue.svg')"
											media="(max-width: 1300px)">
							<img :src="require('@/assets/img/svg/finflow_lab_logo_darkblue.svg')"
									 :alt="logo">
						</picture>
					</router-link>
				</div>
				<div class="footer__content">
					<FooterNav :footerNavList="footerNavList" />
					<div class="footer__column">
						<Social />
					</div>
					<div class="footer__risk">
						<div
							:class="{'active' : isshowFooterText}"
							@click="toggleFooterText"
							class="footer__title"
						>{{footerRiskTitle}}</div>
						<p :class="{'active' : isshowFooterText}">{{footerRiskText}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="footer--bottom">
			<div class="center">
				<p>&copy; {{footerCopyText}}</p>
			</div>
		</div>
	</footer>
</template>

<script>
  import Logo from "@/components/Logo";
  import Social from "@/components/Social";
  import FooterNav from "@/components/navigations/FooterNav";

  export default {
    name: "Footer",
		components: {
      Logo,
      Social,
      FooterNav
		},
		data() {
      return {
        isshowFooterText: false,
        footerNavList: [
					{
            id: 1,
					  title: "О КОМПАНИИ",
            isShowSubList: false,
            footerNavSubList: [
							{
                link: "##",
                linkTitle: "О FinFly"
							},
              {
                link: "##",
                linkTitle: "Дорожная карта"
              },
              {
                link: "##",
                linkTitle: "Команда"
              },
              {
                link: "##",
                linkTitle: "Контакты"
              }
						]
					},
          {
            id: 2,
            title: "ПРАВОВАЯ ИНФОРМАЦИЯ",
            isShowSubList: false,
            footerNavSubList: [
              {
                link: "##",
                linkTitle: "Пользовательское соглашение"
              },
              {
                link: "##",
                linkTitle: "Политика использования cookie"
              },
              {
                link: "##",
                linkTitle: "Все документы"
              }
            ]
          }
				],
				footerRiskTitle: "ПРЕДУПРЕЖДЕНИЕ О РИСКАХ",
        footerRiskText: "Торговля на финансовых рынках (в частности торговля с использованием маржинальных инструментов) открывает широкие возможности, и позволяет инвесторам, готовым пойти на риск, получать высокую прибыль, но при этом несет в себе потенциально высокий уровень риска получения убытка. Поэтому, перед началом торговли следует всесторонне рассмотреть вопрос о приемлемости проведения подобных операций с точки зрения имеющихся финансовых ресурсов и уровня знаний. Более подробно здесь.",
				footerCopyText: "2021-2023 Finfly все права защищены"
			}
		},
		methods:{
      toggleFooterText() {
        this.isshowFooterText = !this.isshowFooterText;
			}
		}

  }
</script>
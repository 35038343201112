<template>
	<div class="main-content main-content--robot-connecting">
		<div class="title">{{pageRobotsTitle}}</div>
		<div class="robot__details robot__details-connecting">
			<div class="robot__details-columns">
				<div class="robot__details-column">
					<div class="robot-card__name">{{robotCardListInfo?.robotCardName}}</div>
					<RobotConnecting
						:robotCardListInfo="robotCardListInfo"
					/>
					<form
						class="form form--robot-connecting"
						@submit.prevent
					>
						<div class="form__row-wrapper">
							<div class="form__row">
								<div class="form__label">{{brokerLabelName}}</div>
								<div class="form__input-wrapper">
									<vSelect
										v-model="selectedBroker"
										class="custom-select"
										:options="brokerList"
									/>
								</div>
							</div>
							<div class="form__row">
								<div class="form__label">{{serverLabelName}}</div>
								<div class="form__input-wrapper">
									<vSelect
										v-model="selectedServer"
										class="custom-select custom-select--dark"
										:options="serverList"
									/>
								</div>
							</div>
						</div>

						<div class="form__row-wrapper">
							<div class="form__row">
								<CustomInputText
									:label="labelInput"
								/>
							</div>
							<div class="form__row">
								<CustomPasswordInput
									:label="labelInputPass"
								/>
							</div>
						</div>

						<div class="form__row form__row--submit">
							<CustomButton
								class="btn--outline"
							> {{btnNameForm}}</CustomButton>
						</div>
					</form>
				</div>
				<div class="robot__details-column robot__details-column--right">
					<div class="robot-strategy-profit__text">
						{{robotCardListInfo?.strategyTypeText}}
					</div>

					<RobotCardImg
						:imgUrl="robotCardListInfo?.imgUrl"
						:imgAlt="robotCardListInfo?.imgAlt"
					/>

					<div class="notice">
						<div class="notice__icn">
							{{icnText}}
						</div>
						<div class="notice__text">
							{{noticeText}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import RobotConnecting from "@/components/robot-card/RobotConnecting";
  import RobotCardImg from "@/components/robot-card/RobotCardImg";
  import vSelect from 'vue-select';
  import CustomPasswordInput from "@/components/common/CustomPasswordInput";
  import CustomInputText from "@/components/common/CustomInputText";
  import CustomButton from "@/components/common/CustomButton";

  export default {
    name: "Robot",
		components: {
      RobotConnecting,
      RobotCardImg,
      vSelect,
      CustomInputText,
      CustomPasswordInput,
      CustomButton
		},
		data() {
      return {
        icnText: "!",
        noticeText: "Результаты доходности, полученные роботом в прошлом,\n" +
          "не гарантируют аналогичной доходности в будущем.",
        labelInput: "Логин",
        btnNameForm: "Запустить",
        labelInputPass: "Пароль",
        pageRobotsTitle: "Подключениe бота к торговому счету",
        brokerLabelName: "Брокер",
        brokerList: [
          'Broker1', 'Broker2', 'Broker3'
        ],
        selectedBroker: 'Broker',
        serverLabelName: "Сервер",
        serverList: [
          'Сервер1', 'Сервер2', 'Сервер3'
        ],
        selectedServer: 'Сервер',
        robotCardListInfo: {
          robotCardName: "Steve USDEUR",
          robotStrategyTitle: "Тип стратегии",
          robotStrategy: "Aggressive",
          strategyTypeImgTitle: "MT4/MT5",
          strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
          strategyTypeImgAlt: "Aggressive",
          strategyProfitTitle: "Доходность, %",
          strategyProfitNum: "175,00",
          strategyProfitUp: "23%",
          strategyMaximumTitle: "Максимальная просадка, %",
          strategyMaximumNum: "0,100",
          imgUrl: require('@/assets/img/personal_robot_01.png'),
          imgAlt: "Steve",
        strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
				}
			}
		}
  }
</script>
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/pages/Home.vue'
import BotDetails from "@/components/pages/BotDetails";
import MyRobots from "@/components/pages/MyRobots";
import Robot from "@/components/pages/Robot";
import MyRobotsDetails from "@/components/pages/MyRobotsDetails";
import Wallet from "@/components/pages/Wallet";
import PersonalAccount from "@/components/pages/PersonalAccount";
import BrokerPartners from "@/components/pages/BrokerPartners";
import ReferralProgram from "@/components/pages/ReferralProgram";
import ContactUs from "@/components/pages/ContactUs";
import FQA from "@/components/pages/FQA";
import Login from "@/components/pages/Login";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bot-details',
    name: 'BotDetails',
    component: BotDetails
  },
  {
    path: '/robot',
    name: 'Robot',
    component: Robot
  },
  {
    path: '/my-robots',
    name: 'MyRobots',
    component: MyRobots
  },
  {
    path: '/my-robots-details',
    name: 'MyRobotsDetails',
    component: MyRobotsDetails
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/partners',
    name: 'BrokerPartners',
    component: BrokerPartners
  },
  {
    path: '/personal-account',
    name: 'PersonalAccount',
    component: PersonalAccount
  },
  {
    path: '/referral-program',
    name: 'ReferralProgram',
    component: ReferralProgram
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/fqa',
    name: 'FQA',
    component: FQA
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      layout: 'SimpleLayout'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
	<div class="wrapper wrapper--simple">
		<div class="center">
			<Main>
				<slot/>
			</Main>
		</div>
	</div>
</template>

<script>
  import Main from "@/layouts/Main";

  export default {
    name: "SimpleLayout",
		components: {
      Main
		}
  }
</script>
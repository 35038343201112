<template>
	<div class="transactions-table__wrap">
		<div class="transactions-table">
			<div
				class="transactions-table__header">
				<div
					v-for="(el,i) in headerList"
					:key="i"
					:style="{width: itemsTablebWidth}"
					class="transactions-table__header-item">
					{{el?.title}}
				</div>
			</div>
			<div class="transactions-table__body">
				<div
					v-for="(row,i) in rowsList"
					:key="i"
					class="transactions-table__row">

					<div
						v-for="(column, index) in row?.columnsList"
						:key="index"

						class="transactions-table__item"
						:class="{'transactions-table__item-icon': column?.imgUrl}"
					>
						<div class="transactions-table__mob-title">{{column?.title}}</div>
						<span
							v-if="column?.imgUrl"
							class="icon"
						>
							<img :src="column?.imgUrl" :alt="column?.title">
						</span>
						<span v-else>{{column?.text}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "TransactionsTable",
		props: {
      headerList: {
        type: Array,
        default: []
      },
      rowsList: {
        type: Array,
				default: []
			},
      columnsList: {
        type: Array,
				default: []
			}
		},
		data() {
      return {
        itemsTablebWidth: `${100/this.headerList.length}%`,
			}
		}
  }
</script>

<style lang="scss">
	.transactions-table__item {
		width: 100%;

		@media(min-width: 960px) {
			width: v-bind(itemsTablebWidth);
		}
	}

</style>
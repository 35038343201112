<template>
	<div class="profile-account__title">
		<div
			v-if="imgUrl"
			class="profile-account__title-icn">
			<img :src="imgUrl" :alt="title">
		</div>
		{{title}}
		<div
			v-if="imgUrlToolTip"
			class="profile-account__tooltip-icn">
			<img :src="imgUrlToolTip" :alt="title">
		</div>
		<div class="profile-account__tooltip">
			{{textToolTip}}
		</div>
	</div>
</template>

<script>
  export default {
    name: "ProfileAccountTitle",
		props: {
      title: {
        type: String,
				default: ''
			},
			imgUrl: {
        type: String,
				default: ''
			},
			imgUrlToolTip: {
        type: String,
				default: ''
			},
			toolTip: {
        type: Boolean,
				default: false
			},
      textToolTipShow: {
        type: Boolean,
				default: false
			},
      textToolTip: {
        type: String,
        default: ''
      },
		}
  }
</script>
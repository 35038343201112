

<template>
	<div class="lang">
		<div @click="swichLang" class="lang__text"><img src="@/assets/img/svg/culture-logo.svg" alt="Ru"/><span>RU</span></div>
		<div class="lang__list">
			<div class="lang__list-item" data-lang="ru"><span>RU</span></div>
			<div class="lang__list-item" data-lang="en"><span>EN</span></div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "Lang",
  }
</script>

<script setup>
  import {useI18n} from 'vue-i18n'

  const {t, locale} = useI18n()


  const swichLang = () => {
    locale.value === 'en' ? locale.value = 'ru' : locale.value = 'en'
    localStorage.setItem('lang', locale.value)
  }
</script>
<template>
	<div class="robot-card__img">
		<img :src="imgUrl" :alt="imgAlt">
	</div>
</template>

<script>
  export default {
    name: "RobotCardImg",
		props: {
      imgUrl: {
        type: String,
        default: ''
      },
      imgAlt: {
        type: String,
        default: ''
      },
		}
  }
</script>
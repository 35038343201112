<template>
	<div
		class="robot-card__wrap">
		<div class="robot-card__header">
			<div
				@click="$router.push({name: 'BotDetails'})"
				class="robot-card__name">{{robotCardListInfo?.robotCardName}}</div>
		</div>
		<div class="robot-card__block-wrapper">
			<div class="robot-card__block">

					<RobotStrategyType
						:robotStrategyTitle="robotCardListInfo?.robotStrategyTitle"
						:robotStrategy="robotCardListInfo?.robotStrategy"
						:strategyTypeImgUrl="robotCardListInfo?.strategyTypeImgUrl"
						:strategyTypeImgAlt="robotCardListInfo?.strategyTypeImgAlt"
						:strategyTypeImgTitle="robotCardListInfo?.strategyTypeImgTitle"
					/>

				<div class="robot-strategy-profit__info">
					<div class="robot-strategy-profit">
						<RobotStrategyProfitInfo
							:strategyProfitTitle="robotCardListInfo?.strategyProfitTitle"
							:strategyProfitNum="robotCardListInfo?.strategyProfitNum"
							:strategyProfitUp="robotCardListInfo?.strategyProfitUp"
							:strategyMaximumTitle="robotCardListInfo?.strategyMaximumTitle"
							:strategyMaximumNum="robotCardListInfo?.strategyMaximumNum"
						/>
					</div>

					<div class="robot-strategy-profit__text">
						{{robotCardListInfo?.strategyTypeText}}
					</div>
				</div>

			</div>

			<RobotCardImg
				:imgUrl="robotCardListInfo?.imgUrl"
				:imgAlt="robotCardListInfo?.imgAlt"
			/>

		</div>

		<RobotTariffPlans
			:tarifPlansTitle="robotCardListInfo?.tarifPlansTitle"
			:tarifPlansList="robotCardListInfo?.tarifPlansList"
		/>

<!--		<RobotTariffBtns-->
<!--			:btnTitle="robotCardListInfo?.btnTitle"-->
<!--		/>-->
		<div class="robot__tariff-btns-wrap">
			<CustomButton
				@click="freeAcc"
				class="btn--outline"
			>
				{{btnTitleFree}}
			</CustomButton>
			<CustomButton
				@click="periodAcc"
				class="btn--outline"
			>
				{{btnTitlePeriod}}
			</CustomButton>
		</div>

	</div>
</template>

<script>
  import CustomRadioGroupItem from "@/components/common/RadioGroup/CustomRadioGroupItem";
  import CustomButton from "@/components/common/CustomButton";
  import RobotStrategyType from "@/components/robot-card/RobotStrategyType";
  import RobotTariffPlans from "@/components/robot-card/RobotTariffPlans";
  import RobotCardImg from "@/components/robot-card/RobotCardImg";
  import RobotTariffBtns from "@/components/robot-card/RobotTariffBtns";
  import RobotStrategyProfitInfo from "@/components/robot-card/RobotStrategyProfitInfo";

  export default {
    name: "RobotCard",
		components: {
      CustomRadioGroupItem,
      CustomButton,
      RobotStrategyType,
      RobotTariffPlans,
      RobotCardImg,
      RobotTariffBtns,
      RobotStrategyProfitInfo
		},
		data(){
      return {
        btnTitleFree: "Подключить бесплатно",
        btnTitlePeriod: "Пробный период 30 дней"
			}
		},
		props: {
      robotCardListInfo: {
        type: Object,
				default: {}
			},
		},
		methods: {
      freeAcc(){
        this.$bus.$emit('open-modal-free-plan-activation');
			},
      periodAcc(){
        this.$bus.$emit('open-modal-tarif-plans-trial');
			}
		}
  }
</script>
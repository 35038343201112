export const parseDate = (str) => {
    let parts = str.split(" ");
    let dateparts = parts[0].split("-");
    let timeparts = (parts[1] || "").split(":");
    let year = +dateparts[0];
    let month = +dateparts[1];
    let day = +dateparts[2];
    let hours = timeparts[0] ? +timeparts[0] : 0;
    let minutes = timeparts[1] ? +timeparts[1] : 0;
    let seconds = timeparts[2] ? +timeparts[2] : 0;

    let date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    return {date, year, month, day};
};

export const parseDateAndTimeString = (dateAndTimeString) => {
    if (!dateAndTimeString) return '';
    const dateString = dateAndTimeString.split(' ')[0];
    const timeString = dateAndTimeString.split(' ')[1];
    const dateParts = dateString.split('-');
    const timeParts = timeString.split(':');

    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    if (!day || !month || !year) return '';
    return `${day}.${month}.${year}`
};

export const formatDate = (date) => {
    return date + ' ' + "00:00:00";
};
<template>
	<div class="state-account state-account--all-statistics">
		<div class="state-account__title">{{overAllStatisticsTitle}}</div>
		<div class="state-account__table">
			<div
				v-for="(item,i) in overAllStatisticsList"
				class="state-account__table-item">
				<div class="state-account__table-title">{{item?.title}}</div>
				<div class="state-account__table-content">{{item?.content}}</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "OverAllStatistics",
    props: {
      overAllStatisticsTitle: {
        type: String,
        default: ''
      },
      overAllStatisticsList: {
        type: Array,
        default: []
      }
    }
  }
</script>
<template>
	<div class="partners__card">
		<div class="partners__row">
			<div class="partners__title-wrap">
				<div
					v-if="partnersCardInfo.title"
					class="partners__title">{{partnersCardInfo.title}}</div>
				<div
					v-if="partnersCardInfo.copyText"
					class="partners__copy" v-html="partnersCardInfo.copyText"></div>
			</div>
			<div
				v-if="partnersCardInfo.imgUrl"
				class="partners__img">
				<img :src="partnersCardInfo.imgUrl" :alt="partnersCardInfo.title">
			</div>
		</div>
		<div
			v-if="partnersCardInfo.partnersContent"
			class="partners__content"  v-html="partnersCardInfo.partnersContent"></div>
		<div
			v-if="partnersCardInfo.btnTitle"
			@click="$bus.$emit('open-modal-open-acc')"
			class="btn btn--outline">{{partnersCardInfo.btnTitle}}</div>
	</div>
</template>

<script>
  export default {
    name: "PartnersCard",
		props: {
      partnersCardInfo: {
        type: Object,
				default: {}
			}
		}
  }
</script>
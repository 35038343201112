

<template>
	<div class="wrapper robots" @keydown.esc="clickOutside">
		<Header @isShowMobMenu="isShowMobMenu" />

		<div class="center">
			<div
				:class="{'open': isOpen}"
				class="aside">
				<MenuList
					:menuListData="menuListData"
				/>
			</div>
			<Main>
				<slot/>
			</Main>
		</div>

		<Footer />
	</div>

	<DefaultModal
		class="modal--tarif-active"
		modalName="tarif-plans"
		:modalTitle="modalTitleTarifPlans"
	>

		<template #default="{elem}">
		<div class="modal__tariff-plans-item">
				<div class="modal__tariff-plans-item--date">{{elem.date}} {{elem.month}}</div>
				<div class="modal__tariff-plans-item--num">$ {{elem.num}}.00</div>
			</div>
			<p>{{modalMsgTarifPlans}}</p>
			<div class="btn__modal-wrap">
				<CustomButton
					@click="activateTarif(elem)"
					class="btn btn--outline">{{btnTarifPlansConfirm}}</CustomButton>
				<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
			</div>
		</template>
	</DefaultModal>


	<DefaultModal
		class="modal--tarif-active modal--tarif-activate"
		modalName="tarif-plans-activate"
		:modalTitle="modalTitleTarifPlansActive"
	>
		<template #default="{elem}">
			<div class="modal__tariff-plans-item">
				<div class="modal__tariff-plans-item--date">{{elem.date}} {{elem.month}}</div>
				<div class="modal__tariff-plans-item--num">$ {{elem.num}}.00</div>
			</div>

			<div v-html="modalMsgTarifPlansActive"></div>
			<div class="btn__modal-wrap">
				<CustomButton
					@click="robotsAcc"
					class="btn btn--outline">{{btnTarifPlansConfirmActive}}</CustomButton>
				<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
			</div>
		</template>
	</DefaultModal>

	<DefaultModal
		class="modal--tarif-trial"
		modalName="tarif-plans-trial"
		:modalTitle="modalTitleTarifPlansTrial"
	>
		<div v-html="modalMsgTarifPlansTrial"></div>
		<div class="btn__modal-wrap">
			<CustomButton
				@click="robotsAccFree"
				class="btn btn--outline">{{btnTarifPlansConfirmActive}}</CustomButton>
			<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
		</div>
	</DefaultModal>

	<DefaultModal
		class="modal--tarif-replenish"
		modalName="tarif-replenish"
		:modalTitle="modalTitleReplenish"
	>
		<div v-html="modalMsgReplenish"></div>
		<div class="btn__modal-wrap">
			<CustomButton class="btn btn--outline">{{btnReplenish}}</CustomButton>
			<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
		</div>
	</DefaultModal>

	<DefaultModal
		class="modal--tarif-open-acc"
		modalName="open-acc"
		:modalTitle="modalTitleOpenAcc"
	>
		<div v-html="modalMsgOpenAcc"></div>
		<div class="btn__modal-wrap">
			<CustomButton class="btn btn--outline">{{btnSelectBroker}}</CustomButton>
			<CustomButton class="btn">{{btnPartneralink}}</CustomButton>
		</div>
	</DefaultModal>

	<DefaultModal
		class="replenish-acc"
		modalName="replenish-acc"
		:modalTitle="modalTitleTarifPlans"
	>
		<!--не готово-->
		<ChangeAmount
			@change-amount="onInput"
			:fixedPaymentList="fixedPaymentList"
		/>
		<div class="form__row">
			<CustomInputText
				@input="onInput"
				:model-value="modelValue"
				:label="labelInputReplenishAcc"
			/>
		</div>
		<div class="form__row">
			<div class="form__label">{{methodLabelName}}</div>
			<div class="form__input-wrapper">
				<vSelect
					v-model="selectedMethod"
					class="custom-select custom-select--dark"
					:options="methodList"
				/>
			</div>
		</div>

		<div class="btn__modal-wrap">
			<CustomButton class="btn btn--outline">{{btnReplenishAcc}}</CustomButton>
			<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
		</div>
	</DefaultModal>

	<DefaultModal
		class="free-plan-activation"
		modalName="free-plan-activation"
		:modalTitle="modalTitleRequestAcc"
	>
		<div class="modal__columns">
			<div class="modal__column">
				<div class="form__row">
					<div class="form__label">{{requestAccLabelName}}</div>

					<div class="form__input-wrapper">
						<vSelect
							v-model="selectedBrokerComp"
							class="custom-select custom-select--dark"
							:options="brokerCompList"
						/>
					</div>
				</div>
			</div>
			<div class="modal__column">
				<div class="form__row">
					<CustomInputText
						:label="labelInputRequestAcc"
					/>
				</div>
			</div>
		</div>

		<div class="form__row">
			<CustomTextarea
				v-model="message"
				name="yourmessage"
				:isSuccess="false"
				:isError="false"
				:label="labelTextAreaRequestAcc"
			/>
		</div>

		<div class="btn__modal-wrap">
			<CustomButton class="btn btn--outline">{{btnSubmit}}</CustomButton>
			<CustomButton class="btn">{{btnTarifPlansCancel}}</CustomButton>
		</div>
	</DefaultModal>

	<DefaultModal
		class="plan-activation-success"
		modalName="plan-activation-success"
		:modalTitle="modalTitleSuccess"
		:modalMsg="modalMsgSuccess"
	>
		<div class="btn__modal-wrap">
			<CustomButton class="btn btn--outline">{{btnSuccess}}</CustomButton>
		</div>
	</DefaultModal>
</template>

<script>




  import Main from "@/layouts/Main";
  import Header from "@/layouts/Header";
  import Footer from "@/layouts/Footer";
  import MenuList from "@/components/navigations/MenuList";
  import vClickOutside from 'click-outside-vue3'
  import DefaultModal from "@/components/modals/DefaultModal";
  import CustomButton from "@/components/common/CustomButton";
  import CustomInputText from "@/components/common/CustomInputText";
  import vSelect from 'vue-select';
  import CustomTextarea from "@/components/common/CustomTextarea";
  import ChangeAmount from "@/components/ChangeAmount";

  export default {
    name: "AppLayout",

    directives: {
      clickOutside: vClickOutside.directive
    },

		components: {
      Main,
      Header,
      Footer,
      MenuList,
      DefaultModal,
      CustomButton,
      CustomInputText,
      vSelect,
      CustomTextarea,
      ChangeAmount
		},

		data() {
      return {
        fixedPaymentList: [
					{
            title: '50',
            amount:  '50',
					},
          {
            title: '120',
            amount:  '120',
          },
          {
            title: '220',
            amount:  '220',
          },
          {
            title: '400',
            amount:  '400',
          }
				],
        isOpen: false,
        btnSuccess: "OK",
        modalMsgSuccess: "После проверки предоставленной информации Вы получите уведомление об активации бесплатного тарифа на e-mail адрес, который использовался для регистрации в нашей системе.",
        modalTitleSuccess: "Ваша заявка успешно отправлена",
        btnSubmit: "Отправить",
        labelTextAreaRequestAcc: "Примечание (не обязательно):",
        message: '',
        labelInputRequestAcc: "Введите e-mail, на который зарегистрирован Ваш аккаунт у брокера-партнера (обязательно):",
        brokerCompList: ['компания1', 'компания2'],
        selectedBrokerComp: "компания",
        requestAccLabelName: "Выберите название  брокерской компании, в которой Вы регистрировались по нашей партнерской ссылке.",
        modalTitleRequestAcc: "Заявка на активацию бесплатного тарифа",
        btnReplenishAcc: "Пополнить",
        selectedMethod: "method1",
        methodList: ['method1', 'method2', 'method3'],
        methodLabelName: "Выберите способ пополнения",
        labelInputReplenishAcc: "Укажите сумму",
        modalMsgOpenAcc: "<p>Для бесплатного подключения робота Вам необходимо зарегистрироваться по нашей партнёрской ссылке в любой из рекомендуемых нами брокерских компаний.</p> <p>Посмотреть список доступных брокеров Вы можете на странице “Брокеры и партнеры”.</p>",
        btnSelectBroker: "Перейти к выбору брокера",
        btnPartneralink: "У меня уже открыт счет по партнерской ссылке",
        modalTitleOpenAcc: "Открытие счета",
        btnReplenish: "Пополнить счет",
        modalMsgReplenish: "Пополните счет удобным для Вас способом и повторите попытку.",
        modalTitleReplenish: "На Вашем счету недостаточно средств для активации выбранного тарифа",
        modalTitleTarifPlansTrial: "Активация пробного периода",
        modalMsgTarifPlansTrial: "<p>Во время пробного периода Вы можете подключать торговых роботов только к демо-счетам!</p><p>Для реальных торговых счетов пробный период недоступен. Для подключения доступны только демо-счета, которые открыты в одной из рекомендуемых нами брокерских компаний.</p>",
        btnTarifPlansTrial: "Открыть демо-счет",
        btnTarifPlansTrialConnect: "Подключить робота к демо-счету",
        btnTarifPlansConfirmActive: "Подключить робота к торговому счету",
        modalTitleTarifPlansActive: "Вы успешно активировали тариф",
        modalMsgTarifPlansActive: "<p> Теперь вы можете подключить робота к вашему торговому счету. </p> <p> <strong>Обратите внимание!</strong> Для продления действия тарифного плана рекомендуем внести средства на ваш счет <strong>за 3 дня до его окончания</strong>.</p>",
        btnTarifPlansCancel: "Отменить",
        btnTarifPlansConfirm: "Подтвердить",
        modalTitleTarifPlans: "Вы активируете тариф",
        modalMsgTarifPlans: "Необходимая сумма будет автоматически списана с вашего счета.",
        menuListData: [
          {
            href: "/",
            title: "Роботы",
            icn: require('@/assets/img/svg/robots_icon.svg'),
            active: true
          },
          {
            href: "/wallet",
            title: "Мой кошелек",
            icn: require('@/assets/img/svg/wallet_icon.svg'),
          },
          {
            href: "/my-robots",
            title: "Мои роботы",
            icn: require('@/assets/img/svg/my_robots_icon.svg'),
          },
          {
            href: "/referral-program",
            title: "Реферальная программа",
            icn: require('@/assets/img/svg/referral_icon.svg'),
          },
          {
            href: "/partners",
            title: "Брокеры и партнеры",
            icn: require('@/assets/img/svg/partners_icon.svg'),
          },
          {
            href: "/contact-us",
            title: "Обратная связь",
            icn: require('@/assets/img/svg/feedback_icon.svg'),
          },
          {
            href: "/personal-account",
            title: "Профиль",
            icn: require('@/assets/img/svg/profile_icon.svg'),
          },
          {
            href: "/fqa",
            title: "FAQ",
            icn: require('@/assets/img/svg/faq_icon.svg'),
          },
          {
            href: "/login",
            title: "Выход",
            icn: require('@/assets/img/svg/out_icon.svg'),
          }
        ]
			}
		},
		methods: {
      onInput(data) {
        console.log(data, 'eventeventevent')
        this.$emit('input', data);
      },

      clickOutside() {
        this.$bus.$emit('closeMobMenu');
      },
      activateTarif(elem) {
        this.$bus.$emit('close-modal-tarif-plans', () => {
          this.isOpen = false;
        });
        this.$bus.$emit('open-modal-tarif-plans-activate', elem);
			},
      robotsAccFree(){
        this.$router.push({name: 'Robot'});
        this.$bus.$emit('close-modal-tarif-plans-trial', () => {
          this.isOpen = false;
        });
      },
			robotsAcc(){
        this.$router.push({name: 'Robot'});
        this.$bus.$emit('close-modal-tarif-plans-activate', () => {
          this.isOpen = false;
				});
			}
		},
    created() {
      this.$bus.$on('isShowMobMenu', () => {
        this.isOpen = !this.isOpen;
      });
      this.$bus.$on('closeMobMenu', () => {
        this.isOpen = false;
      });
    }

  }
</script>
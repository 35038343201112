<template>
  <main class="main-content__wrap">
    <slot/>
  </main>
</template>

<script>
  export default {
    name: "Main"
  }
</script>

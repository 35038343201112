<template>
	<div class="main-content main-content--contact-us">
		<div class="title">{{pageContactUslTitle}}</div>
		<div class="history-transactions__wrap">
			<div class="history-transactions__top">
				<div class="history-transactions__top-item">
					<div class="btn btn--outline">{{contactUsBtnText}}</div>
				</div>
				<SortPage
					:sortPageTitle="sortPageTitle"
					:sortPageText="sortPageText"
					:sortPageList="sortPageList"
				/>
				<ContactUsTable
					:rowsList="rowsList"
					:headerList="headerList"
					:answerList="answerList"
				/>
				<Paginations
					:paginationsList="paginationsList"
				/>
			</div>
		</div>
	</div>
</template>

<script>
  import SortPage from "@/components/SortPage";
  import Paginations from "@/components/Paginations";
  import ContactUsTable from "@/components/ContactUsTable";

  export default {
    name: "ContactUs",
		components: {
      SortPage,
      Paginations,
      ContactUsTable
		},
		data() {
      return {
        answerList: {
          name: 'Dear John Smith!',
          text: 'Sincerely yours',
          subtext: 'finFlow Lab Team Support'
				},
        rowsList: [
          {
            columnsList: [
              {
                checkBoxId: "1",
              },
              {

                title: "От кого",
                text: "413",
              },
              {

                title: "Тема",
                text: "GroweX welcomes you",
              },
              {

                title: "Создано",
                text: "16.5.2023 14:48",
              },
            ],
          },
          {
            columnsList: [
              {
                checkBoxId: "2",
              },
              {

                title: "От кого",
                text: "413",
              },
              {

                title: "Тема",
                text: "GroweX welcomes you",
              },
              {

                title: "Создано",
                text: "16.5.2023 14:48",
              },
            ]
          },
          {
            columnsList: [
              {
                checkBoxId: "3",
              },
              {

                title: "От кого",
                text: "Support",
              },
              {

                title: "Тема",
                text: "Welcome to finFlow!",
              },
              {

                title: "Создано",
                text: "16.5.2023 14:48",
              },
            ],
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore\n' +
              'et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut\n' +
              'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum\n' +
              'dolore eu fugiat nulla pariatur.  Excepteur sint occaecat cupidatat non proident, sunt in culpa\n' +
              'qui officia deserunt mollit anim id est laborum.</p><p><a href="https://samplelink.com/registration_confirmation_congratulation">https://samplelink.com/registration_confirmation_congratulation</a></p> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore\n' +
              'et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut\n' +
              'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum\n' +
              'dolore eu fugiat nulla pariatur.  Excepteur sint occaecat cupidatat non proident, sunt in culpa\n' +
              'qui officia deserunt mollit anim id est laborum.</p>'
          }
        ],
        headerList: [
          {
            checkboxSelectAll: '3'
          },
          {
            title: "От кого"
          },
          {
            title: "Тема"
          },
          {
            title: "Создано"
          },
        ],
        contactUsBtnText: "Написать",
        paginationsList: [
          {
            num: '1',
            active: true,
            link: '##'
          },
          {
            num: '2',
            link: '##'
          },
          {
            num: '3',
            link: '##'
          },
          {
            next: '>',
            link: '##'
          },
          {
            last: '>>',
            link: '##'
          }
        ],
        sortPageTitle: "Показать",
        sortPageText: "строк",
        sortPageList: [
          {
            num: '15',
            active: true
          },
          {
            num: '50',
          },
          {
            num: '100',
          },
          {
            num: '200',
          },
        ],
        pageContactUslTitle: "Обратная связь"
			}
		}
  }
</script>
<template>
	<li
		class="footer-nav__sublist-item"
	>
		<a
			:href="link"
			class="footer-nav__sublist-link"
		>
			{{linkTitle}}
		</a>
	</li>
</template>

<script>
  export default {
    name: "FooterSubNavItem",
		props: {
      link: {
        type: String,
				default: ''
			},
      linkTitle: {
        type: String,
				default: ''
			}
		}
  }
</script>
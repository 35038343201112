<template>
	<div class="form__wrap">
		<form>
			<div class="form__content">
				<div class="form__row">
					<CustomInputText
						name="auth_login"
						placeholder="Email"
						:modelValue="login"
						label="Login"
					/>
					<CustomInputText
						name="auth_password"
						placeholder="Password"
						:modelValue="password"
						label="Password"
					/>
				</div>
				<CustomButton
					type="submit"
					class="btn btn--outline"
				>
					SIGN IN
				</CustomButton>
			</div>
		</form>
	</div>
</template>

<script>
  import CustomInputText from "@/components/common/CustomInputText";
  import CustomButton from "@/components/common/CustomButton";

  export default {
    name: "LoginForm",
		components: {
      CustomInputText,
      CustomButton
		},
    data() {
      return {
        login: '',
        password: '',
        loginErrors: [],
      }
    },
  }
</script>
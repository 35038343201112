<template>
	<div class="main-content main-content--profile">
		<div class="title">{{pageRobotsTitle}}</div>
		<ProfileAccountItem
			:profileAccountList="profileAccountList"
		/>

		<div class="personal-account__wrap">
			<div class="personal-account__top">
				<div class="personal-account__title">{{activeSessionTitle}}</div>
			</div>

			<TransactionsTable
				:headerList="headerActiveSessionList"
				:rowsList="rowsActiveSessionList"
			/>
		</div>

		<div class="personal-account__wrap">
			<div class="personal-account__top">
				<div class="personal-account__title">{{logTitle}}</div>
			</div>

			<TransactionsTable
				:headerList="headerLogList"
				:rowsList="rowsLogList"
			/>
		</div>

	</div>
</template>

<script>
  import ProfileAccountItem from "@/components/profile-account-item/ProfileAccountItem";
  import TransactionsTable from "@/components/wallet/TransactionsTable";
  export default {
    name: "PersonalAccount",
		components: {
      ProfileAccountItem,
      TransactionsTable
		},
		data() {
      return {
        headerLogList: [
          {
            title: "Дата"
          },
          {
            title: "Устройство"
          },
          {
            title: "Браузер"
          },
          {
            title: "IP Адрес"
          },
          {
            title: "Геопозиция"
          },
          {
            title: "Статус"
          },
        ],
        rowsLogList: [
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Active",
              },
            ]
          },
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Active",
              },
            ]
          },
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Inactive",
              },
            ]
          },
        ],
        logTitle: "Журнал доступа",
        headerActiveSessionList: [
          {
            title: "Дата"
          },
          {
            title: "Устройство"
          },
          {
            title: "Браузер"
          },
          {
            title: "IP Адрес"
          },
          {
            title: "Геопозиция"
          },
          {
            title: "Статус"
          },
        ],
        rowsActiveSessionList: [
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Active",
              },
            ]
          },
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Active",
              },
            ]
          },
          {
            columnsList: [
              {

                title: "Дата",
                text: "2023.05.08 11:45",
              },
              {

                title: "Устройство",
                text: "Apple iPhone XS Max",
              },
              {

                title: "Браузер",
                text: "Safari 10.2.2",
              },
              {
                title: "IP Адрес",
                text: "85.238.102.181"
              },
              {
                title: "Геопозиция",
                text: "Saint Vincent and the Grenadines",
              },
              {

                title: "Статус",
                text: "Inactive",
              },
            ]
          },
        ],
        activeSessionTitle: "Активные сессии",
        pageRobotsTitle: "Профиль",
        profileAccountList: {
          id: "ID:8954597",
					email: "example@mail.com",
          infoTitle: "Дата и время последнего логина",
					date: "2022-03-11 15:23:29",
					ip: "85.238.102.181",
					btnAddLog: "Добавить Логин",
          accVerificationText: "Аккаунт Верифицирован",
          personalInfoTitle: "Личные Данные",
          btnAddPersonalInfo: "Заполнить",
          langSystemTitle: "Язык Системы",
          changePassTitle: "Изменение Пароля",
          btnChangePass: "Изменить Пароль",
          apiKeyTitle: "API-Ключ",
          btnApiKeyAdd: "Получить API-Ключ",
          deletePersonalInfoTitle: "Удалить Личные Данные",
          btnDeletePersonalInfo: "Удалить",
          btnAuthenticationTitle: "Не Активирована",
          authenticationTitle: "Двухфакторная Аутентификация",
          authenticationText: "Используется для вывода денежных средств и управления настройками безопасности",
          authenticationSubText: "<p>Использование двухфакторной аутентификации повысит защищенность Вашего аккаунта.</p><p>Мы настоятельно рекомендуем вам актвировать двухфакторную аутентификацию, так как это самая оптимальная система безопасности, которая обеспечивает безопасность Вашему аккаунту и сохранность Ваших средств.</p>",
          authenticationGoogle: "Google Authenticator",
          authenticationGoogleImgUrl: require('@/assets/img/svg/icn-google.svg'),
          btnAuthenticationGoogle: "Включен",
          authenticationEmail: "Email Аутентификация",
          authenticationEmailImgUrl: require('@/assets/img/svg/icn-mail.svg'),
          btnAuthenticationEmail: "Выключен",
          isActiveBtnAuthenticationGoogle: true,
          isActiveBtnAuthenticationEmail: false,
          yourIpTitle: "Ваш IP: 85.238.102.181",
					btnYourIp: "Доверять этому IP",
          yourIpFiltrTitle: "IP Фильтр",
          imgUrlToolTip: require('@/assets/img/svg/icn-tooltip.svg'),
				}
			}
		}
  }
</script>
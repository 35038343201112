<template>
	<div class="tabs"
	>
		<div
			class="tabs__header">
				<div
					v-for="(item, index) in itemsTabsHeader"
					:key="index"
					class="tabs__header-item"
					:class="{ 'active': isSelectedTab === index }"
					@click="changeTab(index)"
				>
					{{item}}
				</div>
		</div>
		<div class="tabs__content">
			<div
				v-for="(item, index) in itemsTabsHeader"
				:key="index"
				class="tabs__content-item"
				:class="{ 'active': isSelectedTab === index }"
			>
				<slot :name="`tab-content-${index + 1}`"></slot>
			</div>
		</div>
	</div>
</template>


<script>


  export default {
    name: 'Tabs',
    props: {
      itemsTabsHeader: {
        type: Array,
        default: () => {
          return []
        },
      },

      selectedTab: {
        type: Number,
        default: 0
      },

    },

    data(){
      return{
        isSelectedTab: this.selectedTab,

        itemsTabWidth: `${100/this.itemsTabsHeader.length}%`,
        tabViewportWidth: `${100*this.itemsTabsHeader.length}%`,
      }
    },

    methods: {
      changeTab( index) {
        this.isSelectedTab  = index;
      }
    }

  }
</script>
<template>
	<div class="main-content">
		<div class="title">{{pagePartnersTitle}}</div>

		<div class="partners">
			<PartnersCard
				v-for="(item, index) in partnersCardInfo"
				:key="index"
				:partnersCardInfo="item"
			/>
		</div>
	</div>
</template>

<script>
  import PartnersCard from "@/components/partners-card/PartnersCard";

  export default {
    name: "BrokerPartners",
		components: {
      PartnersCard
		},
		data() {
      return {
        pagePartnersTitle: "Брокеры и партнеры",
        partnersCardInfo: [
					{
            title: "RoboForex",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
						imgUrl: require('@/assets/img/svg/logo-robox.svg'),
					},
          {
            title: "FxPro",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
            imgUrl: require('@/assets/img/svg/FxPro_logo.svg'),
          },
          {
            title: "Gerchik&Co",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
            imgUrl: require('@/assets/img/svg/GerchicCo.svg'),
          },
          {
            title: "Swissquote",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
            imgUrl: require('@/assets/img/svg/Swissquote_logo.svg'),
          },
          {
            title: "FlowBank",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
            imgUrl: require('@/assets/img/svg/FlowBank_logo.svg'),
          },
          {
            title: "DXM",
            copyText: "&copy; 2023, FirstMarinerBank",
            partnersContent: "<strong>700 збп |||</strong> ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut lLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ametmet, consectetuer Lorem ipsum dolor sitLorem ipsum dolor sit ater",
            btnTitle: "Открыть счет",
            imgUrl: require('@/assets/img/svg/XM_Logo.svg'),
          }
				]
			}
		},
  }
</script>
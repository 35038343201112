<!--роль placeholder выполняет label-->
<template>
  <div class="form-group">
    <input :id="name"
           v-maska="'##.##.####'"
           class="form-control form-control_full-width form-control--date"
           :class="invalidClass"
           :disabled="disabled"
           :value="parsedDate"
           @maska="changeValue($event.target.value)"
    >
    <label v-if="!hidden_input"
           :for="name"
           class="label-name">
      <span class="content-name">{{ label }}</span>
    </label>
  </div>
</template>

<script>
  import { parseDateAndTimeString } from "@/utils/date";
  import { maska } from 'maska';

  export default {
    name: "InputDate",
    directives: { maska },
    emits: ['update:modelValue', 'input'],
    props: {
      type: {
        default: "text",
        type: String
      },
      hidden_input: {
        default: false,
        type: Boolean,
        required: false
      },
      name: {
        default: "",
        type: String,
        required: true
      },
      label: {
        default: "",
        type: String
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      placeholder: {
        type: String,
        default: ' '
      },
      disabled: {
        type: Boolean,
        default: false
      },
      modelValue: {
        type: String,
        default: ''
      },
    },
    computed: {
      parsedDate() {
        return parseDateAndTimeString(this.modelValue);
      },
      invalidClass() {
        return !this.isValid && this.isValid !== null ? 'form-control_invalid' : '';
      }
    },
    methods: {
      changeValue(value) {
        const dateString = this.generateDateString(value);
        this.$emit('update:modelValue', dateString);
      },

      generateDateString(date) {
        const dateArray = date.split('.');
        const day = dateArray[0];
        const month = dateArray[1];
        const year = dateArray[2];

        return `${year}-${month}-${day} 00:00:00`;
      },
    },
  }
</script>

<style scoped>
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
</style>
<template>
	<div class="profile-account__avatar">
		<div class="profile-account__avatar-img">
			<img :src="avatarImgUrl" alt="avatar">
		</div>
		<div class="profile-account__avatar-add">
			{{avatarText}}
		</div>
	</div>
</template>

<script>
  export default {
    name: "AccountAvatar",
		props: {
      avatarImgUrl: {
        type: String,
				default: ''
			},
      avatarText: {
        type: String,
        default: ''
      }
		}
  }
</script>
<template>
	<div class="main-content">
		<div class="title">{{$t('ROBOTS_PAGE_TITLE')}}</div>
		<div class="robot__list">
			<RobotCard
				v-for="(item, index) in robotCardList"
				:key="index"
				:robotCardListInfo="item"
			/>
		</div>
	</div>
</template>

<script>
  import RobotCard from "@/components/robot-card/RobotCard";


  export default {
    name: "Home",
    props: {
      isShowAside: {
        type: Boolean,
        default: false
      }
    },
		components: {
      RobotCard,
		},
		data() {
      return {
        pageRobotsTitle: "Роботы",
        robotCardList: [
					{
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
						],
					},
          {
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Stable",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
          },
          {
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
          },
          {
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Stable",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
          },
          {
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
          },
          {
            robotCardName: "Steve USDEUR",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Stable",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
          },
				],
			}
		}
  }
</script>
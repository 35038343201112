<template>
	<nav class="footer-nav">
		<ul
			v-for="(item, index) in footerNavList"
			:key="item.id"
			class="footer-nav__list">
			<li
				class="footer-nav__item"
			>
				<span
					:class="{'active' : item.isShowSubList}"
					class="footer-nav__item-title"
					@click="showSubMenu(item.id, footerNavList)"
				>{{item?.title}}</span>
				<ul
					:class="{'active' : item.isShowSubList}"
					class="footer-nav__sublist"
				>
					<FooterSubNavItem
						v-for="(subItem, index) in item.footerNavSubList"
						:key="index"
						:link="subItem?.link"
						:linkTitle="subItem?.linkTitle"
					/>
				</ul>
			</li>
		</ul>
	</nav>
</template>

<script>
  import FooterSubNavItem from "@/components/navigations/FooterSubNavItem";

  export default {
    name: "FooterNav",
    props: {
      footerNavList: {
        type: Array,
        default: []
      }
    },
		components: {
      FooterSubNavItem
		},
		data() {
      return {

			}
		},

		methods: {
      showSubMenu(itemId, array) {
        array.map(el => {
          if(el.id === itemId) {
            el.isShowSubList = !el.isShowSubList;
          } else {
            el.isShowSubList = false;
          }
        })
			},
		}
  }
</script>
<template>
  <button
    :disabled="disabled ? true : false"
    class="btn"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    name: "CustomButton",
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
    }
  }
</script>
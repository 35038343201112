<template>
  <div class="form__field-box">
    <div class="form__field-container"
         :class="isValid? '' : 'invalid'">
      <input
        :id="name"
        type="checkbox"
        :name="name"
        class="form__field-checkbox"
        :checked="checked"
        @input="onChange($event)" >
      <label class="form__field-label form__field-label--checkbox"
             :for="name"
      >
        <slot></slot>
      </label>
    </div>
    <div class="form__field-msg">{{errorMsg}}</div>
  </div>
</template>

<script>
  export default {
    name: "CustomCheckBox",
    props: {
      name: {
        default: "",
        type: String,
        required: true
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      errorMsg: {
        type: [String || Array],
        default: ''
      },
      checked: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        default: "",
        type: String
      }
    },
    methods: {
      onChange(e) {
        this.$emit('change', e);
      },
    }
  }
</script>

<style scoped>

</style>
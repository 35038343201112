import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/main.scss';
import EventBus from "./utils/eventBus";
import {createI18n, useI18n} from "vue-i18n";
import  { languages } from "./locales"
import { defaultLocale } from "./locales"

const localStorageLang = localStorage.getItem('lang')

const messages = Object.assign(languages)
const i18n = createI18n({
  legacy: false,
  locale: localStorageLang || defaultLocale,
  fallbackLocale: 'ru',
  messages
})

const app = createApp(App);

app.config.globalProperties.$bus = EventBus;
app.use(store);
app.use(router);
app.use(i18n);

app.mount('#app');
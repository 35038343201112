<template>
	<div
		v-for="item in walletList"
		:key="item?.currency"
		class="wallet-card">
			<div class="wallet-card__top">
				<div class="wallet-card__top-wrap">
					<div class="wallet-card__sing">
						<span>{{item?.currencySing}}</span>
					</div>
					<div class="wallet-card__currency">
						{{item?.currency}}
					</div>
				</div>

				<div class="wallet-card__top-currencyfield">{{item?.currencyField}}</div>
			</div>
			<div class="wallet-card__block">
				<WalletCardBlock
					:walletCardBlockList="item?.walletCardBlockList"
				/>
			</div>
			<div class="wallet-card__btns">
<!--				<button-->
<!--					v-for="btn in item?.btnList"-->
<!--					:key="btn?.name"-->
<!--					class="btn btn&#45;&#45;outline">{{btn?.name}}</button>-->
				<CustomButton
					@click="freeAcc"
					class="btn--outline"
				>
					{{btnTitleFree}}
				</CustomButton>
				<CustomButton
					class="btn--outline"
				>
					{{btnTitlePeriod}}
				</CustomButton>
			</div>
	</div>
</template>

<script>
  import WalletCardBlock from "@/components/wallet/WalletCardBlock";
  import CustomButton from "@/components/common/CustomButton";

  export default {
    name: "WalletCard",
		components: {
      WalletCardBlock,
      CustomButton
		},
		props: {
      walletList: {
        type: Object,
				default: {}
			}
		},
    data(){
      return {
        btnTitleFree: "Пополнить счет",
        btnTitlePeriod: "Вывести средства"
      }
    },
    methods: {
      freeAcc(){
        this.$bus.$emit('open-modal-replenish-acc');
      },
    }
  }
</script>
<template>
	<div
		class="header__info-item"
		:class="itemInfo?.alt"
	>
		<img
			:src="itemInfo?.imgUrl"
			:alt="itemInfo?.alt"
		>
		<Marker
			v-if="itemInfo?.notice"
			:markerText="itemInfo.notice"
		/>
	</div>
</template>

<script>
  import Marker from "@/components/Marker";

  export default {
    name: "HeaderInfoItem",
		components: {
      Marker

		},
    props: {
      itemInfo: {
        type: Object,
        default:() => {
          return {}
        }
      }
    }
  }
</script>
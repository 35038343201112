<template>
	<div class="main-content">
		<div class="title">{{pageRobotsTitle}}</div>

		<div class="select__list">
			<vSelect
				v-model="selectedBalance"
				class="custom-select custom-select--dark"
				:options="balanceList"
			/>
			<vSelect
				v-model="selectedRobots"
				class="custom-select custom-select--dark"
				:options="robotList"
			/>
			<vSelect
				v-model="selectedNumBalance"
				class="custom-select custom-select--dark"
				:options="balanceNumList"
			/>
			<vSelect
				v-model="selectedBroker"
				class="custom-select custom-select--dark"
				:options="brokerList"
			/>
		</div>

		<div class="robot-card__trade-wrap">
			<RobotCardTrade
				v-for="(item, index) in robotCardList"
				:key="index"
				:robotCardListInfo="item"
			/>
		</div>

<!--		<Tabs-->
<!--			:itemsTabsHeader="itemsTabsHeader"-->
<!--		>-->
<!--			<template #tab-content-1>-->
<!--				<RobotCardTrade-->
<!--					v-for="(item, index) in robotCardList"-->
<!--					:key="index"-->
<!--					:robotCardListInfo="item"-->
<!--				/>-->
<!--			</template>-->

<!--			<template #tab-content-2>-->
<!--				<RobotCardTrade-->
<!--					v-for="(item, index) in robotCardListDemo"-->
<!--					:key="index"-->
<!--					:robotCardListInfo="item"-->
<!--				/>-->
<!--			</template>-->
<!--		</Tabs>-->
	</div>
</template>

<script>
  import vSelect from 'vue-select';
  import Tabs from "@/components/tabs/Tabs";
  import RobotCardTrade from "@/components/robot-card/RobotCardTrade";

  export default {
    name: "MyRobots",
		components: {
      Tabs,
      RobotCardTrade,
      vSelect
		},
		data() {
      return {
        selectedBroker: '№ счета - Все',
        brokerList: [
          'Broker1', 'Broker2', 'Broker3'
        ],
				selectedNumBalance: '№ счета - Все',
        balanceNumList: [
          '11111', '222222', '33333'
        ],
				selectedRobots: 'Робот - Все',
        robotList: [
          'Робот1', 'Робот2', 'Робот3'
        ],
        selectedBalance: 'Счета - Все',
        balanceList: [
          'Счет1', 'Счет2', 'Счет3'
        ],
        pageRobotsTitle: "Мои роботы",
        itemsTabsHeader: ['Торговые счета', 'Демо-счета'],
        robotCardList: [
          {
            demoTrade: "DEMO",
            robotCardName: "Steve USDEUR",
            numberTrade: "№ 4378968934764",
            numberTradeName: "FirstMarinerBank",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            currentBalanceTitle: "Текущий баланс, USD:",
            currentBalance: "280,854.27",
            startDateTitle: "Дата подключения: ",
            startDate: "09/28/2024",
            endDateTitle: "Дата окончания тарифа",
            endDate: "∞",
            equityTitle: "Эквити:",
            equity: "USD 98,200.39",
            btnInfoTitle: "Подробная информация",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
            btnTitle: [
              {title: "Подключить бесплатно"},
              {title: "Пробный период 30 дней"}
            ]
          },
          {
            demoTrade: "DEMO",
            robotCardName: "Steve USDEUR",
            numberTrade: "№ 4378968934764",
            numberTradeName: "FirstMarinerBank",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            currentBalanceTitle: "Текущий баланс, USD:",
            currentBalance: "280,854.27",
            startDateTitle: "Дата подключения: ",
            startDate: "09/28/2024",
            endDateTitle: "Дата окончания тарифа",
            endDate: "∞",
            equityTitle: "Эквити:",
            equity: "USD 98,200.39",
            btnInfoTitle: "Подробная информация",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
            btnTitle: [
              {title: "Подключить бесплатно"},
              {title: "Пробный период 30 дней"}
            ]
          },
          {
            robotCardName: "Steve USDEUR",
            numberTrade: "№ 4378968934764",
            numberTradeName: "FirstMarinerBank",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            currentBalanceTitle: "Текущий баланс, USD:",
            currentBalance: "280,854.27",
            startDateTitle: "Дата подключения: ",
            startDate: "09/28/2024",
						endDateTitle: "Дата окончания тарифа",
            endDate: "∞",
            equityTitle: "Эквити:",
            equity: "USD 98,200.39",
            btnInfoTitle: "Подробная информация",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
            btnTitle: [
              {title: "Подключить бесплатно"},
              {title: "Пробный период 30 дней"}
            ]
          },
          {
            robotCardName: "Steve USDEUR",
            numberTrade: "№ 4378968934764",
            numberTradeName: "FirstMarinerBank",
            imgUrl: require('@/assets/img/personal_robot_01.png'),
            imgAlt: "Steve",
            robotStrategyTitle: "Тип стратегии",
            robotStrategy: "Aggressive",
            strategyTypeImgTitle: "MT4/MT5",
            strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
            strategyTypeImgAlt: "Aggressive",
            strategyProfitTitle: "Доходность, %",
            strategyProfitNum: "175,00",
            strategyProfitUp: "23%",
            strategyMaximumTitle: "Максимальная просадка, %",
            strategyMaximumNum: "0,100",
            currentBalanceTitle: "Текущий баланс, USD:",
            currentBalance: "280,854.27",
            startDateTitle: "Дата подключения: ",
            startDate: "09/28/2024",
            endDateTitle: "Дата окончания тарифа",
            endDate: "∞",
            equityTitle: "Эквити:",
            equity: "USD 98,200.39",
            btnInfoTitle: "Подробная информация",
            strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
            tarifPlansTitle: "Тарифные планы",
            tarifPlansList: [
              {
                id: '1',
                date: "1",
                month: "месяц",
                num: "50"
              },
              {
                id: '2',
                date: "3",
                month: "месяца",
                num: "120"
              },
              {
                id: '3',
                date: "6",
                month: "месяцев",
                num: "220"
              },
              {
                id: '4',
                date: "1",
                month: "год",
                num: "400"
              }
            ],
            btnTitle: [
              {title: "Подключить бесплатно"},
              {title: "Пробный период 30 дней"}
            ]
          },
        ],
        // robotCardListDemo: [
        //   {
        //     demoTrade: "DEMO",
        //     robotCardName: "Steve USDEUR",
        //     numberTrade: "№ 4378968934764",
        //     numberTradeName: "FirstMarinerBank",
        //     imgUrl: require('@/assets/img/personal_robot_01.png'),
        //     imgAlt: "Steve",
        //     robotStrategyTitle: "Тип стратегии",
        //     robotStrategy: "Aggressive",
        //     strategyTypeImgTitle: "MT4/MT5",
        //     strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
        //     strategyTypeImgAlt: "Aggressive",
        //     strategyProfitTitle: "Доходность, %",
        //     strategyProfitNum: "175,00",
        //     strategyProfitUp: "23%",
        //     strategyMaximumTitle: "Максимальная просадка, %",
        //     strategyMaximumNum: "0,100",
        //     currentBalanceTitle: "Текущий баланс, USD:",
        //     currentBalance: "280,854.27",
        //     startDateTitle: "Дата подключения: ",
        //     startDate: "09/28/2024",
				// 		endDateTitle: "Дата окончания тарифа",
        //     endDate: "∞",
        //     equityTitle: "Эквити:",
        //     equity: "USD 98,200.39",
        //     btnInfoTitle: "Подробная информация",
        //     strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
        //     tarifPlansTitle: "Тарифные планы",
        //     tarifPlansList: [
        //       {
        //         date: "1",
        //         month: "месяц",
        //         num: "50"
        //       },
        //       {
        //         date: "3",
        //         month: "месяца",
        //         num: "120"
        //       },
        //       {
        //         date: "6",
        //         month: "месяцев",
        //         num: "220"
        //       },
        //       {
        //         date: "1",
        //         month: "год",
        //         num: "400"
        //       }
        //     ],
        //     btnTitle: [
        //       {title: "Подключить бесплатно"},
        //       {title: "Пробный период 30 дней"}
        //     ]
        //   },
        //   {
        //     demoTrade: "DEMO",
        //     robotCardName: "Steve USDEUR",
        //     numberTrade: "№ 4378968934764",
        //     numberTradeName: "FirstMarinerBank",
        //     imgUrl: require('@/assets/img/personal_robot_01.png'),
        //     imgAlt: "Steve",
        //     robotStrategyTitle: "Тип стратегии",
        //     robotStrategy: "Aggressive",
        //     strategyTypeImgTitle: "MT4/MT5",
        //     strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
        //     strategyTypeImgAlt: "Aggressive",
        //     strategyProfitTitle: "Доходность, %",
        //     strategyProfitNum: "175,00",
        //     strategyProfitUp: "23%",
        //     strategyMaximumTitle: "Максимальная просадка, %",
        //     strategyMaximumNum: "0,100",
        //     currentBalanceTitle: "Текущий баланс, USD:",
        //     currentBalance: "280,854.27",
        //     startDateTitle: "Дата подключения: ",
        //     startDate: "09/28/2024",
        //     endDateTitle: "Дата окончания тарифа",
        //     endDate: "∞",
        //     equityTitle: "Эквити:",
        //     equity: "USD 98,200.39",
        //     btnInfoTitle: "Подробная информация",
        //     strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
        //     tarifPlansTitle: "Тарифные планы",
        //     tarifPlansList: [
        //       {
        //         date: "1",
        //         month: "месяц",
        //         num: "50"
        //       },
        //       {
        //         date: "3",
        //         month: "месяца",
        //         num: "120"
        //       },
        //       {
        //         date: "6",
        //         month: "месяцев",
        //         num: "220"
        //       },
        //       {
        //         date: "1",
        //         month: "год",
        //         num: "400"
        //       }
        //     ],
        //     btnTitle: [
        //       {title: "Подключить бесплатно"},
        //       {title: "Пробный период 30 дней"}
        //     ]
        //   },
        // ],
      }
		}
  }
</script>
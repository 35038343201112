<template>
	<component :is="layout">
		<router-view  />
	</component>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import SimpleLayout from "@/layouts/SimpleLayout";

  export default {
    components: {
      AppLayout,
      SimpleLayout
		},
    computed: {
      layout() {
        const layoutName = this.$route.meta.layout || 'AppLayout';
        return layoutName || 'AppLayout';
      }
		}
  }
</script>
import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuth: false,
  },
  getters: {
    getIsAuth: state => {
      return state.isAuth;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

<template>
	<div class="history-transactions__wrap">
		<div class="history-transactions__top">
			<div class="history-transactions__top-item">
				{{historyTransactionsTitle}}
			</div>
			<div class="history-transactions__top-item">
				<span>Период:</span>
				<InputDate v-model="date"/>
			</div>
		</div>

			<SortPage
				:sortPageTitle="sortPageTitle"
				:sortPageText="sortPageText"
				:sortPageList="sortPageList"
			/>

		<TransactionsTable
			:headerList="headerList"
			:rowsList="rowsList"
		/>
		<Paginations
			:paginationsList="paginationsList"
		/>

	</div>
</template>

<script>
  import Paginations from "@/components/Paginations";
  import InputDate from "@/components/common/InputDate";
  import TransactionsTable from "@/components/wallet/TransactionsTable";
  import SortPage from "@/components/SortPage";

  export default {
    name: "HistoryTransactions",
		components: {
      Paginations,
      InputDate,
      TransactionsTable,
      SortPage
		},
		data() {
      return {

        paginationsList: [
					{
            num: '1',
            active: true,
            link: '##'
					},
          {
            num: '2',
            link: '##'
          },
          {
            num: '3',
            link: '##'
          },
          {
            next: '>',
            link: '##'
          },
          {
            last: '>>',
            link: '##'
          }
				],
        sortPageList: [
					{
            num: '15',
            active: true
					},
          {
            num: '50',
          },
          {
            num: '100',
          },
          {
            num: '200',
          },
				],
        sortPageTitle: "Показать",
        sortPageText: "строк",
        historyTransactionsTitle: "Истории операций",
        date: '',
        rowsList: [
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {
                title: "Тип транзакции",
                text: "Приход",
              },
              {
                title: "Сумма",
                text: "USD 55,100.00",
              },
              {
                title: "Комментарий",
                text: "QIWI",
              },
              {
                title: "Статус",
                text: "Проведена"
              }
            ]
          },
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {

                title: "Тип транзакции",
                text: "Расход",
              },
              {
                title: "Сумма",
                text: "GPB -25,800.00",
              },
              {
                title: "Комментарий",
                text: "Perfect Money",
              },
              {
                title: "Статус",
                text: "Проведена"
              }
            ]
          },
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {

                title: "Тип транзакции",
                text: "Расход",
              },
              {

                title: "Сумма",
                text: "GPB -25,800.00",
              },
              {

                title: "Комментарий",
                text: "Perfect Money",
              },
              {
                title: "Статус",
                text: "В обработке"
              }
            ]
          },
          {
            columnsList: [
              {
                title: "Дата",
                text: "23:56 10.09.2022",
              },
              {

                title: "Тип транзакции",
                text: "Расход",
              },
              {

                title: "Сумма",
                text: "GPB -25,800.00",
              },
              {

                title: "Комментарий",
                text: "Perfect Money",
              },
              {
                title: "Статус",
                text: "Отменена"
              }
            ]
          }
        ],
        headerList: [
          {
            title: "Дата"
          },
          {
            title: "Тип транзакции"
          },
          {
            title: "Сумма"
          },
          {
            title: "Комментарий"
          },
          {
            title: "Статус"
          }
        ],
			}
		}
  }
</script>
<template>
  <div
    class="form__field-box form__field-box--textarea"
    :class="{'success' : isSucsess, 'error': isError}"
  >
		<label
			v-if="label"
			class="form__label"
			:for="name"
		>
			{{ label }}
		</label>
    <div
      class="form__field-wrap"
    >
      <textarea 
        :id="name"
        :name="name"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="disabled ? true : false"
        class="form__field form__field-textarea"
        @input="$emit('update:modelValue', $event.target.value)"
      >

      </textarea>
    </div>
    <div
      v-if="isSucsess || isError"
      class="form__field-msg">{{msgText}}</div>
  </div>
</template>

<script>
  export default {
    name: "CustomTextarea",
    props: {
      modelValue: {
        type: [Number, String],
        default: ''
      },

      label: {
        type: String,
        default: ''
      },

      name: {
        type: String,
        default: ''
      },

      placeholder: {
        type: String,
        default: ''
      },

      disabled: {
        type: String,
        default: ''
      },

      isSucsess: {
        type: Boolean,
        default: false
      },

      isError: {
        type: Boolean,
        default: false
      },

    },
    computed: {


      msgText() {
        if(this.isSucsess) {

        }else if(this.isError) {

        }
        return ''
      }
    }
  }
</script>
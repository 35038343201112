<template>
	<div class="main-content">
		<div class="title">{{pageRobotsTitle}}</div>
		
		<div class="wallet">
			<div class="wallet__header">
				<div class="wallet__header-item">
					<CustomCheckBox
						name="zerobalance"
					>
						{{checkboxLabelBalance}}
					</CustomCheckBox>
				</div>
				<div class="wallet__header-item">
					<div class="wallet__header-title">{{headerTitleBalanceAll}}</div>
					<div class="wallet__header-balance">{{headerBalanceWeb}}/{{headerBalanceCard}}</div>
				</div>
			</div>

			<div class="wallet-card__wrap">
				<WalletCard :walletList="walletList" />
			</div>

			<HistoryTransactions />
		</div>
	</div>
</template>

<script>
  import CustomCheckBox from "@/components/common/CustomCheckBox";
  import WalletCard from "@/components/wallet/WalletCard";
  import HistoryTransactions from "@/components/wallet/HistoryTransactions";

  export default {
    name: "Wallet",
		components: {
      CustomCheckBox,
      WalletCard,
      HistoryTransactions
		},
		data() {
      return {
        pageRobotsTitle: "Мой кошелек",
        checkboxLabelBalance: "Скрыть с нулевым балансом",
        headerTitleBalanceAll: "Общий баланс",
        headerBalanceCard: "USD 320,008.44",
        headerBalanceWeb: "BTC 10.00000000",
        walletList: [
					{
            currencySing: "$",
            currency: "USD",
            currencyField: "320,008.44",
            walletCardBlockList: [
              {
                title: "Доступные средства",
                content: "310,008.44"
              },
              {
                title: "Зарезервировано",
                content: "10,000.00"
              },
              {
                title: "Эквивалент в EUR",
                content: "284 460,30"
              }
            ],
					},
          {
            currencySing: "€",
            currency: "EUR",
            currencyField: "284,460.30",
            walletCardBlockList: [
              {
                title: "Доступные средства",
                content: "284,460.30"
              },
              {
                title: "Зарезервировано",
                content: "10,000.00"
              },
              {
                title: "Эквивалент в EUR",
                content: "320,008.44"
              }
            ],
          }
				],
			}
		}
  }
</script>
<template>
	<div class="main-content main-content--robot-info">
		<div class="title">{{pageRobotsTitle}}</div>
		<Breadcrumbs
			:breadcrumbsList="breadcrumbsList"
		/>

		<div class="robot__details">
			<div class="robot__details-columns">
				<div class="robot__details-column">
					<RobotStrategyType
						:robotStrategyTitle="robotStrategyTitle"
						:robotStrategy="robotStrategy"
						:strategyTypeImgUrl="strategyTypeImgUrl"
						:strategyTypeImgAlt="strategyTypeImgAlt"
						:strategyTypeImgTitle="strategyTypeImgTitle"
					/>

					<div class="robot__deposit-min">
						<div class="robot__deposit-text">{{robotDepositMInText}}</div>
						<div class="robot__deposit-min-num">{{robotDepositMInNum}}</div>
					</div>

					<RobotTariffPlans
						:tarifPlansTitle="tarifPlansTitle"
						:tarifPlansList="tarifPlansList"
					/>

<!--					<RobotTariffBtns-->
<!--						:btnTitle="btnTitle"-->
<!--					/>-->
					<div class="robot__tariff-btns-wrap">
						<CustomButton
							@click="freeAcc"
							class="btn--outline"
						>
							{{btnTitleFree}}
						</CustomButton>
						<CustomButton
							@click="periodAcc"
							class="btn--outline"
						>
							{{btnTitlePeriod}}
						</CustomButton>
					</div>


					<div class="robot__details-info-text">
						<p><strong>1000 збп</strong> || ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. magna aliquamat vtpat. adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem m dolor sit Loreum
						</p>
					</div>
				</div>

				<div class="robot__details-column img">
					<RobotCardImg
						:imgUrl="imgUrl"
						:imgAlt="imgAlt"
					/>
				</div>
			</div>

			<div class="robot__details-wrapper">
				<div class="robot__details-broker-list">
					<div class="robot__details-broker-column">
						<div class="robot__details-broker-title">{{robotBrokerTitle}}</div>
						<div class="robot__details-broker-img-list">
							<div
								v-for="(img,i) in robotBrokerListImg"
								:key="i"
								class="robot__details-broker-img-item">
								<img :src="img?.imnUrl" :alt="img?.imgAlt">
							</div>
						</div>
					</div>
					<div class="robot__details-broker-column broker">
						<div class="robot__details-broker-title">{{robotMYBrokerTitle}}</div>
						<p>Выбирая платный тариф на любой срок, Вы можете подключить робота к своему торговому счету, открытому в любой брокерской компании, которая предоставляет возможность торговли
							через торговую платформу MetaTrader 4/5.</p>
						<p>	Если нужного вам брокера нет в списке доступных, свяжитесь
							с нами через <a href="##">форму обратной связи</a> , мы оперативно его добавим.</p>
					</div>
				</div>

				<div class="trading-statistics">
					<div class="trading-statistics__title">{{tradingStatisticsTitle}}</div>
					<div class="trading-statistics__table">
						<div
							v-for="(tableItem, i) in tableStatisticList"
							:key="i"
							class="trading-statistics__table-item">
							<div class="trading-statistics__table-title">{{tableItem?.tradingStatisticsTableTitle}}</div>
							<div
								:class="tableItem?.tradingStatisticsTableStatus"
								class="trading-statistics__table-info">{{tableItem?.tradingStatisticsTableInfo}}</div>
						</div>
					</div>
					<div class="trading-statistics__graffic">
						<img :src="require('@/assets/img/trading-statistics-graffic.jpg')" alt="graffic">
					</div>
					<CustomButton class="btn--outline">{{tradingStatisticsMyFxBook}}</CustomButton>
				</div>
			</div>

		</div>
	</div>
</template>


<script>
  import RobotStrategyType from "@/components/robot-card/RobotStrategyType";
  import RobotCardImg from "@/components/robot-card/RobotCardImg";
  import RobotTariffPlans from "@/components/robot-card/RobotTariffPlans";
  import RobotTariffBtns from "@/components/robot-card/RobotTariffBtns";
  import CustomButton from "@/components/common/CustomButton";
  import Breadcrumbs from "@/components/Breadcrumbs";

  export default {
    name: "BotDetails",
    components: {
      RobotStrategyType,
      RobotCardImg,
      RobotTariffPlans,
      RobotTariffBtns,
      CustomButton,
      Breadcrumbs
    },
    data() {
      return {
        breadcrumbsList: [
					{
					  title: 'Роботы',
            link: "/"
					},
          {
            title: 'Steve USDEUR',
          }
				],
        btnTitleFree: "Подключить бесплатно",
        btnTitlePeriod: "Пробный период 30 дней",
        // pageRobotsTitle: "Steve USDEUR | Информация",
        robotStrategyTitle: "Тип стратегии",
        robotStrategy: "Aggressive",
        strategyTypeImgTitle: "MT4/MT5",
        strategyTypeImgUrl: require('@/assets/img/MetaTrader_Logo.png'),
        strategyTypeImgAlt: "Aggressive",
				imgUrl: require('@/assets/img/personal_robot_01.png'),
				imgAlt: "Steve",
				tarifPlansTitle: "Тарифные планы",
        tarifPlansList: [
          {
            id: '1',
            date: "1",
            month: "месяц",
            num: "50"
          },
          {
            id: '2',
            date: "3",
            month: "месяца",
            num: "120"
          },
          {
            id: '3',
            date: "6",
            month: "месяцев",
            num: "220"
          },
          {
            id: '4',
            date: "1",
            month: "год",
            num: "400"
          }
        ],
				btnTitle: [
					{title: "Подключить бесплатно"},
					{title: "Пробный период 30 дней"}
				],
				robotDepositMInText: "Рекомендуемый минимальный депозит для подключения робота",
        robotDepositMInNum: "10,000.00$",
        robotBrokerTitle: "Рекомендуемый брокер",
        robotMYBrokerTitle: "Мой брокер",
        robotBrokerListImg: [
					{
					  imnUrl: require('@/assets/img/svg/icn-roboflex.svg'),
						imgAlt: "roboflex"
					},
          {
            imnUrl: require('@/assets/img/svg/GerchicCo_logo.svg'),
            imgAlt: "GerchicCo"
          },
          {
            imnUrl: require('@/assets/img/svg/icn-flowbank.svg'),
            imgAlt: "flowbank"
          },
          {
            imnUrl: require('@/assets/img/svg/icn-swissqupte.svg'),
            imgAlt: "swissqupte"
          }
				],
        tradingStatisticsTitle: "Торговая статистика",
        tableStatisticList: [
					{
            tradingStatisticsTableTitle: "Общая доходность:",
            tradingStatisticsTableInfo: "+341%",
            tradingStatisticsTableStatus: "trading-statistics__table-up"
					},
          {
            tradingStatisticsTableTitle: "Доходность 24h:",
            tradingStatisticsTableInfo: "-0.63%",
            tradingStatisticsTableStatus: "trading-statistics__table-down"
          },
          {
            tradingStatisticsTableTitle: "Доходность 7d:",
            tradingStatisticsTableInfo: "-1.20%",
            tradingStatisticsTableStatus: "trading-statistics__table-down"
          },
          {
            tradingStatisticsTableTitle: "Доходность 30d:",
            tradingStatisticsTableInfo: "+4.9%",
            tradingStatisticsTableStatus: "trading-statistics__table-up"
          },
          {
            tradingStatisticsTableTitle: "Максимальная просадка:",
            tradingStatisticsTableInfo: "0.6%",
          },
          {
            tradingStatisticsTableTitle: "Профит-фактор::",
            tradingStatisticsTableInfo: "2.04",
          },
				],
        tradingStatisticsMyFxBook: "Подробная статистика на MyFxBook"
        // robotCardList: [
        //   {
        //     imgUrl: require('@/assets/img/personal_robot_01.png'),
        //     imgAlt: "Steve",
        //     strategyProfitTitle: "Доходность, %",
        //     strategyProfitNum: "175,00",
        //     strategyProfitUp: "23%",
        //     strategyMaximumTitle: "Максимальная просадка, %",
        //     strategyMaximumNum: "0,100",
        //     strategyTypeText: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer Lorem ipsum dolor sit",
        //     tarifPlansTitle: "Тарифные планы",
        //     tarifPlansList: [
        //       {
        //         date: "1",
        //         month: "месяц",
        //         num: "50"
        //       },
        //       {
        //         date: "3",
        //         month: "месяца",
        //         num: "120"
        //       },
        //       {
        //         date: "6",
        //         month: "месяцев",
        //         num: "220"
        //       },
        //       {
        //         date: "1",
        //         month: "год",
        //         num: "400"
        //       }
        //     ],
        //     btnTitle: [
        //       {title: "Подключить бесплатно"},
        //       {title: "Пробный период 30 дней"}
        //     ]
        //   },
        // ],
      }
    },
    methods: {
      freeAcc(){
        this.$bus.$emit('open-modal-tarif-plans-trial');
      },
      periodAcc(){
        this.$bus.$emit('open-modal-free-plan-activation');
      }
    }
  }
</script>